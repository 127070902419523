import React, { ComponentProps } from "react";
import { useTranslation } from "react-i18next";

import TextInput from "@krea/customer-features/shared-components/fields/input";
import { useCustomerFeaturesSettingsStore } from "@krea/customer-features/store/app-settings/useCustomerFeaturesSettingsStore";
import { TEST_IDS } from "@krea/customer-features/utils";

export interface IPhoneInputProps extends ComponentProps<typeof TextInput> {
  touched?: boolean;
  errors?: string;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
}

const PhoneInput = (props: IPhoneInputProps) => {
  const { value, touched, errors, label, onBlur, name, handleChange } = props;
  const { t } = useTranslation();
  const countryCode = useCustomerFeaturesSettingsStore(
    (state) => state.countryCode,
  );

  return (
    <TextInput
      size="lg"
      id="mobile_phone_number"
      name={name ?? "mobile_phone_number"}
      type="tel"
      label={label ?? t("applicationForm.commons.phoneLabel")}
      placeholder={t(`applicationForm.commons.phonePlaceholder.${countryCode}`)}
      autoComplete="on"
      value={value ?? ""}
      error={touched && errors ? errors : undefined}
      onChange={(e) => handleChange(e)}
      onBlur={onBlur}
      data-test-id={TEST_IDS.common.forms.phoneInput}
    />
  );
};

export default PhoneInput;
