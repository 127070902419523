import React from "react";

import { twMerge } from "@krea/shared/design-system/tailwind-utils";

export const Error = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={twMerge("tw-text-sm tw-text-red-500", className)}>
      {children}
    </div>
  );
};
