import axios, { AxiosResponse } from "axios";
import dayjs from "dayjs";
import { jwtDecode } from "jwt-decode";

import { settings } from "@krea/customer-features/settings";
import { AppName } from "@krea/shared/types/common";

import { useCustomerFeaturesSettingsStore } from "../store/app-settings/useCustomerFeaturesSettingsStore";

let ongoingRefreshRequest: Promise<
  AxiosResponse<IRefreshTokenResponse>
> | null = null;

interface IRefreshTokenResponse {
  access_token: string;
  refresh_token: string;
}

const refreshToken = async (refresh_token: string) => {
  const axiosInstanceWithoutInterceptor = axios.create();
  const appName = useCustomerFeaturesSettingsStore.getState().appName;
  const isPartnerWeb = appName === AppName.PARTNER;

  const URL = `${settings.backendBaseUrl}${
    isPartnerWeb ? "/partner" : ""
  }/api/v1/auth/refresh-token`;

  if (!ongoingRefreshRequest) {
    ongoingRefreshRequest =
      axiosInstanceWithoutInterceptor.post<IRefreshTokenResponse>(URL, {
        refresh_token,
      });
  }

  try {
    const response = await ongoingRefreshRequest;

    const responseData = response.data;

    localStorage.setItem("accessToken", responseData.access_token);
    localStorage.setItem("refreshToken", responseData.refresh_token);

    ongoingRefreshRequest = null;

    return responseData.access_token;
  } catch (error) {
    const status = (error as { response?: { status: number } }).response
      ?.status;

    if (status && [401, 403].includes(status)) {
      // If the refresh token is invalid, we should remove the tokens from the local storage.
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
    } else {
      console.error("Failed to refresh token", error);
    }

    ongoingRefreshRequest = null;

    return null;
  }
};

const checkAccessAndRefreshTokens = () => {
  const accessTokenValue = localStorage.getItem("accessToken");
  const refreshTokenValue = localStorage.getItem("refreshToken");

  if (!accessTokenValue || !refreshTokenValue) {
    console.log("Not logged in");

    return null;
  }

  const decodedAccessToken = jwtDecode(accessTokenValue);
  const decodedRefreshToken = jwtDecode(refreshTokenValue);

  const hasAccessTokenExpired =
    decodedAccessToken.exp && decodedAccessToken.exp - dayjs().unix() <= 0;
  const hasRefreshTokenExpired =
    decodedRefreshToken.exp && decodedRefreshToken.exp - dayjs().unix() <= 0;

  if (hasRefreshTokenExpired) {
    console.log("Refresh token has expired");

    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");

    return null;
  }

  if (hasAccessTokenExpired) {
    return refreshToken(refreshTokenValue);
  }

  return accessTokenValue;
};

export const getAccessToken = async () => {
  const currentURL = new URL(window.location.href);

  // quickAuth could contain a very short-lived refreshToken so that we can "quickAuth" into customer web from
  // other authenticated sources.
  const quickAuthRefreshToken = currentURL.searchParams.get("quickAuth");
  currentURL.searchParams.delete("quickAuth");

  window.history.replaceState(null, "", currentURL.href);

  if (quickAuthRefreshToken) {
    try {
      const decodedQuickAuthToken = jwtDecode(quickAuthRefreshToken);

      const quickAuthTokenExpired =
        decodedQuickAuthToken.exp &&
        decodedQuickAuthToken.exp - dayjs().unix() <= 0;

      if (!quickAuthTokenExpired) {
        // If we have a quickAuthRefreshToken.
        // We want to use  that to get a fresh set of "normal" tokens.
        return await refreshToken(quickAuthRefreshToken);
      }
    } catch (err) {
      console.error("Failed to decode quickAuth token", err);
    }
  }

  return checkAccessAndRefreshTokens();
};

// eslint-disable-next-line import/no-default-export
export default class HttpService {
  static instance: HttpService | null;

  static getInstance() {
    if (HttpService.instance) {
      return HttpService.instance;
    }

    HttpService.instance = new HttpService();

    return HttpService.instance;
  }

  static createInstanceWithoutInterceptors() {
    return axios.create({
      baseURL: settings.backendBaseUrl,
    });
  }

  setupInterceptors() {
    axios.defaults.withCredentials = false; // otherwise axios seems to send session-cookie credentials SOMETIMES

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error: { response?: unknown }) => {
        return Promise.reject(error.response as Error);
      },
    );

    axios.interceptors.request.use(
      async (config) => {
        const currentAccessToken = await getAccessToken();
        config.headers.Authorization = currentAccessToken
          ? `Bearer ${currentAccessToken}`
          : null;

        const params = new URLSearchParams(window.location.search);
        const auth = params.get("auth");

        if (auth) {
          return { ...config, params: { ...config.params, auth } };
        }

        return config;
      },
      (error) => {
        // Do something with request error
        return Promise.reject(error as Error);
      },
    );
  }
}
