import { gql } from "graphql-request";

export const CMS_SITE_SETTINGS_FRAGMENT = gql`
  fragment CMSSiteSettingsFragment on CmsSiteSettingsType {
    reviewProviderScores {
      provider
      score
      maxScore
      numberOfReviews
      linkToProviderReviews
    }
  }
`;
