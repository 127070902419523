// Source: https://plainjs.com/javascript/utilities/set-cookie-get-cookie-and-delete-cookie-5/
export function getCookie(name: string): string | null {
  const v = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);

  return v ? v[2] : null;
}

export const getCookieDomain = (): string => {
  if (window.location.hostname.includes("localhost")) {
    return "localhost";
  } else if (window.location.hostname.includes("fakturino.se")) {
    return ".fakturino.se";
  } else if (window.location.hostname.includes("krea.se")) {
    return ".krea.se";
  } else if (window.location.hostname.includes("kredita.fi")) {
    return ".kredita.fi";
  }

  return "";
};

const mutateCookie = (name: string, value: string, date: Date): void => {
  const domainPart = `;domain=${getCookieDomain()}`;
  document.cookie = `${name}=${value};path=/;expires=${date.toUTCString()}${domainPart}`;
};

export function setCookie(name: string, value: string, days = 1): void {
  const d = new Date();
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
  mutateCookie(name, value, d);
}

export function deleteCookie(name: string): void {
  // Expire today with value = " " deletes the cookie
  mutateCookie(name, " ", new Date());
}
