import React from "react";
import { useTranslation } from "react-i18next";

import TextInput from "@krea/customer-features/shared-components/fields/input";
import { useCustomerFeaturesSettingsStore } from "@krea/customer-features/store/app-settings/useCustomerFeaturesSettingsStore";
import { TEST_IDS } from "@krea/customer-features/utils";

const EmailInput = (props) => {
  const {
    value,
    touched,
    errors,
    isPreliminaryBid,
    label,
    onBlur,
    handleChange,
  } = props;
  const { t } = useTranslation();
  const countryCode = useCustomerFeaturesSettingsStore(
    (state) => state.countryCode,
  );

  return (
    <TextInput
      size="lg"
      id="email"
      type="email"
      name="email"
      label={label || t("applicationForm.commons.emailLabel")}
      placeholder={t(`applicationForm.commons.emailPlaceholder.${countryCode}`)}
      autoComplete="on"
      value={value || ""}
      error={touched && errors ? errors : null}
      onChange={(e) => handleChange(e)}
      onBlur={onBlur}
      tooltip={isPreliminaryBid && t("applicationForm.commons.emailTooltip")}
      data-test-id={TEST_IDS.common.forms.emailInput}
    />
  );
};

export default EmailInput;
