import { cva, VariantProps } from "class-variance-authority";
import { ComponentProps, ReactNode } from "react";

import twMerge from "@krea/customer-features/utils/tailwind-utils";

import Preloader from "../preloader";

const buttonVariants = cva(
  [
    "tw-cursor-pointer tw-rounded tw-font-semibold tw-leading-[1.3] tw-outline-none",
    "focus:tw-outline-none",
    "active:tw-outline-none",
    "disabled:tw-cursor-not-allowed disabled:tw-border-none disabled:tw-bg-[#ebe8ef] disabled:tw-text-[#989898]",
  ],
  {
    variants: {
      color: {
        primary: "tw-bg-primary hover:tw-bg-primary/95",
        secondary: "tw-bg-black hover:tw-bg-black/80",
      },
      size: {
        sm: "tw-min-h-9 tw-text-sm",
        md: "tw-min-h-10 tw-text-base",
        lg: "tw-min-h-12 tw-text-lg",
        xl: "tw-min-h-16 tw-text-xl",
      },
      variant: {
        contained: "tw-text-white",
        outlined: "tw-border hover:tw-border",
        ghost: "tw-text-transparent hover:tw-text-transparent",
        text: [
          "tw-border-none tw-bg-transparent hover:tw-bg-transparent active:tw-bg-transparent",
          "disabled:tw-border-none disabled:tw-bg-transparent",
          "disabled:hover:tw-border-none disabled:hover:tw-bg-transparent",
          "disabled:active:tw-border-none disabled:active:tw-bg-transparent",
          "disabled:focus:tw-border-none disabled:focus:tw-bg-transparent",
        ],
      },
      selected: {
        true: "tw-cursor-default",
      },
    },
    defaultVariants: { color: "primary", size: "md", variant: "contained" },
    compoundVariants: [
      {
        variant: "contained",
        color: "primary",
        selected: true,
        className: "hover:tw-bg-primary active:tw-bg-primary",
      },
      {
        variant: "text",
        color: "primary",
        className:
          "tw-border-none tw-bg-transparent tw-text-primary hover:tw-text-primary active:tw-text-primary",
      },
      {
        variant: "text",
        color: "secondary",
        className:
          "tw-border-none tw-bg-transparent tw-text-black hover:tw-text-black active:tw-text-black",
      },
      {
        color: "primary",
        variant: "outlined",
        className: [
          "tw-border-primary tw-bg-transparent tw-text-primary",
          "hover:tw-border-primary hover:tw-bg-primary hover:tw-text-white",
          "active:tw-border-primary active:tw-bg-primary active:tw-text-white",
        ],
      },

      {
        color: "primary",
        variant: "outlined",
        selected: true,
        className: [
          "tw-border-primary tw-bg-primary-150 tw-text-primary",
          "hover:tw-border-primary hover:tw-bg-primary-150 hover:tw-text-primary",
          "active:tw-border-primary active:tw-bg-primary-150 active:tw-text-primary",
        ],
      },
      {
        color: "secondary",
        variant: "outlined",
        className: [
          "tw-border-black tw-bg-transparent tw-text-black",
          "hover:tw-border-black hover:tw-bg-black/80 hover:tw-text-white",
          "active:tw-border-black active:tw-bg-black/80 active:tw-text-white",
        ],
      },
    ],
  },
);

const iconVariants = cva(
  "has-svg:tw-block has-svg:!tw-h-auto has-svg:!tw-w-full has-svg:tw-fill-current has-svg:tw-text-current",
  {
    variants: {
      size: {
        sm: "tw-w-4",
        md: "tw-w-[18px]",
        lg: "tw-w-[20px]",
        xl: "tw-w-[24px]",
      },
    },
  },
);

type ButtonVariants = VariantProps<typeof buttonVariants>;

interface ButtonProps
  extends Omit<ComponentProps<"button">, "color">,
    ButtonVariants {
  endIcon?: ReactNode;
  justify?: "start" | "center" | "end" | "between" | "around";
  startIcon?: ReactNode;
  block?: boolean;
  preloader?: boolean;
  selected?: boolean;
}

const Button = ({
  className,
  children,
  color = "primary",
  size = "md",
  variant = "contained",
  endIcon,
  justify = "center",
  startIcon,
  block = false,
  onClick,
  disabled,
  preloader,
  selected = false,
  type = "button",
  ...rest
}: ButtonProps) => {
  const buttonClassName = twMerge(
    buttonVariants({ color, size, variant, selected }),
    `tw-relative tw-items-center tw-justify-${justify}`,
    className,
    {
      "tw-inline-flex": !block,
      "tw-flex": block,
      "tw-py-1": variant !== "text",
      "tw-px-3": size !== "md" && size !== "lg" && variant !== "text",
      "tw-px-4": size !== "sm" && size !== "lg" && variant !== "text",
      "tw-px-5": size !== "sm" && size !== "md" && variant !== "text",
      "tw-px-0 tw-h-auto tw-bg-transparent": variant === "text",
    },
  );

  return (
    <button
      style={{
        transition:
          "color 0.3s ease 0s,background-color 0.3s ease 0s,box-shadow 0.3s ease 0s,border-color 0.3s ease 0s",
      }}
      className={buttonClassName}
      onClick={onClick}
      disabled={!!disabled || !!preloader}
      type={type}
      {...rest}
    >
      {startIcon && (
        <div
          className={twMerge(iconVariants({ size }), {
            "tw-mr-3": size !== "sm",
            "tw-mr-2": size === "sm",
            invisible: preloader,
          })}
        >
          {startIcon}
        </div>
      )}

      <div className={twMerge({ invisible: preloader })}>{children}</div>

      {endIcon && (
        <div
          className={twMerge(
            iconVariants({ size }),
            { "tw-invisible": preloader },
            "tw-ml-3",
          )}
        >
          {endIcon}
        </div>
      )}

      {preloader && (
        <Preloader size={size} className={twMerge("tw-absolute tw-h-full")} />
      )}
    </button>
  );
};

export default Button;
