import React, { useState, useEffect } from "react";
import { getI18n, useTranslation } from "react-i18next";

import { useFormatCurrency } from "@krea/customer-features/hooks";
import {
  requestedRealEstateLoanAmountValuesDefault,
  requestedRealEstateLoanAmountValuesLarge,
  requestedLoanAmountValuesDefault,
  requestedLoanAmountValuesLarge,
  requestedLoanAmountValues,
} from "@krea/customer-features/services/formService";
import { useCustomerFeaturesSettingsStore } from "@krea/customer-features/store/app-settings/useCustomerFeaturesSettingsStore";
import { amountValuesList } from "@krea/customer-features/utils";
import { CountryCode } from "@krea/shared/types/common";

import { FormatNumber } from "../../Formatters";
import { Slider } from "../../Slider";
import LabeledRadioButton from "../labeled-radio-button";

const AmountSliderSelector = ({
  onChange,
  currentSlider,
  isRealEstate,
}: {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  currentSlider: string;
  isRealEstate: boolean;
}) => {
  const { countryCode, currency } = useCustomerFeaturesSettingsStore(
    (state) => ({
      currency: state.currency,
      countryCode: state.countryCode,
    }),
  );
  const [, formattedCurrency] = useFormatCurrency({ currency });

  const selector = (isRealEstate: boolean, countryCode: CountryCode) => {
    const i18n = getI18n();
    const productType = !isRealEstate ? "loanApplication" : "realEstate";

    return [
      {
        title: i18n.t(
          `applicationForm.commons.sliderSelector.${productType}.defaultTitle.${countryCode}`,
          { currency: formattedCurrency },
        ),
        slider: "default",
      },
      {
        title: i18n.t(
          `applicationForm.commons.sliderSelector.${productType}.largeTitle.${countryCode}`,
          { currency: formattedCurrency },
        ),
        slider: "large",
      },
    ];
  };

  return (
    <div className="tw-mx-0 tw-mb-6 tw-flex tw-flex-col tw-gap-[8px] md:tw-flex-row md:tw-justify-between md:tw-gap-[14px]">
      {selector(isRealEstate, countryCode).map(({ title, slider }, index) => (
        <div key={index} className="tw-w-full tw-px-0 md:tw-w-1/2">
          <LabeledRadioButton
            title={title}
            name="amount-radio"
            value={slider}
            checked={currentSlider === slider}
            onChange={onChange}
          />
        </div>
      ))}
    </div>
  );
};

// Same as Formatters.FormatCurrency but hacked to show "Välj belopp" instead of 0
const customFormatCurrency = (
  value: string | number,
  formattedCurrencyValue: (value: string | number) => string,
) => {
  const { t } = getI18n();

  if (value === "" || value === 0) {
    return t("applicationForm.commons.requestedAmountLabel");
  }

  const formattedValue = formattedCurrencyValue(value);

  return formattedValue ? formattedValue.replace(/\./g, " ") : formattedValue; // edge formats with dots instead of spaces
};

const AmountSlider = ({
  name,
  value,
  touched,
  isPreliminaryBid,
  label,
  isRealEstate,
  onInputBlur,
  setInputValue,
  errors,
}: {
  name?: string;
  value: number;
  touched: boolean;
  errors?: string;
  isRealEstate: boolean;
  isPreliminaryBid?: boolean;
  label?: string;
  onInputBlur?: (() => void) | undefined;
  setInputValue: (name: string, value: number, shouldValidate: boolean) => void;
}) => {
  const { t } = useTranslation();
  const { countryCode, currency } = useCustomerFeaturesSettingsStore(
    (state) => ({
      countryCode: state.countryCode,
      currency: state.currency,
    }),
  );

  const LoanAmountValues = () => {
    const values = (ratio: number) => {
      if (!isRealEstate) {
        if (isPreliminaryBid)
          return {
            default: amountValuesList(requestedLoanAmountValues, ratio),
            extended: amountValuesList(requestedLoanAmountValues, ratio),
          };
        else
          return {
            default: amountValuesList(requestedLoanAmountValuesDefault, ratio),
            extended: amountValuesList(requestedLoanAmountValuesLarge, ratio),
          };
      } else {
        return {
          default: amountValuesList(
            requestedRealEstateLoanAmountValuesDefault,
            ratio,
          ),
          extended: amountValuesList(
            requestedRealEstateLoanAmountValuesLarge,
            ratio,
          ),
        };
      }
    };

    switch (countryCode) {
      case CountryCode.SE:
        return values(1);

      case CountryCode.FI:
        return values(10);

      default:
        return null;
    }
  };

  const [sliderValue, setSliderValue] = useState(
    !isRealEstate ? value : LoanAmountValues()!.default[11],
  );
  const [isCustomRequestedLoan, setIsCustomRequestedLoan] = useState(false);

  const [currentSlider, setCurrentSlider] = useState("default");

  const [sliderValues, setSliderValues] = useState(LoanAmountValues()!.default);

  const [formattedCurrencyValue, currencyValue] = useFormatCurrency({
    currency,
  });

  const requestedLoanAmount = (value: number, shouldValidate: boolean) => {
    const inputName = name ?? "requested_loan_amount";
    setInputValue(inputName, value, shouldValidate);
  };

  const numberClosestToValue = (
    numbers: number[],
    value: number,
    currentSlider: string,
  ) => {
    return numbers.reduce((prev, curr) => {
      // return Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev
      if (numbers[currentSlider === "default" ? 1 : 0]) {
        return numbers[currentSlider === "default" ? 1 : 0];
      } else if (Math.abs(curr - value) < Math.abs(prev - value)) {
        return curr;
      } else {
        return prev;
      }

      // return value < numbers[currentSlider === "default" ? 1 : 0]
      //   ? numbers[currentSlider === "default" ? 1 : 0]
      //   : Math.abs(curr - value) < Math.abs(prev - value)
      //     ? curr
      //     : prev;
    });
  };

  const onRequestedLoanToggle = (currentSlider: string) => {
    if (isCustomRequestedLoan) {
      const number = numberClosestToValue(
        sliderValues,
        sliderValue,
        currentSlider,
      );
      setSliderValue(number);
      requestedLoanAmount(number, false);
    }

    setIsCustomRequestedLoan(!isCustomRequestedLoan);
  };

  useEffect(() => {
    setSliderValues(
      currentSlider === "default"
        ? LoanAmountValues()!.default
        : LoanAmountValues()!.extended,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlider]);

  useEffect(() => {
    const sliderSegment = sliderValues.find((num) => num === value);

    if (!sliderSegment) {
      onRequestedLoanToggle(currentSlider);
    } else {
      setSliderValue(sliderSegment);
      requestedLoanAmount(sliderSegment, false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlider]);

  useEffect(() => {
    // Handle setting initial values when not using slider when initial animation

    let valueToSet = value;

    if (!isCustomRequestedLoan) {
      if (currentSlider === "default") {
        const max =
          LoanAmountValues()!.default[LoanAmountValues()!.default.length - 1];
        const min = LoanAmountValues()!.default[0];

        // if value is not between min and max
        if (value && (value < min || value > max)) {
          if (isRealEstate) {
            valueToSet = LoanAmountValues()!.default[11];
          } else {
            valueToSet = LoanAmountValues()!.default[10];
          }
        }
      } else {
        const max =
          LoanAmountValues()!.extended[LoanAmountValues()!.extended.length - 1];
        const min = LoanAmountValues()!.extended[0];

        // if value is not between min and max.
        if (value && (value < min || value > max)) {
          if (isRealEstate) {
            valueToSet = LoanAmountValues()!.extended[6];
          } else {
            valueToSet = LoanAmountValues()!.extended[5];
          }
        }
      }
    }

    setSliderValue(valueToSet);
    requestedLoanAmount(valueToSet, false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlider, isCustomRequestedLoan, value]);

  const onInputLoanAmountChange = ({ target }: { target: EventTarget }) => {
    const parsed = parseFloat(
      (target as HTMLInputElement).value.trim().replace(/\s/g, ""),
    );
    const final = Number.isNaN(parsed) ? 0 : parsed;

    setSliderValue(final);
    requestedLoanAmount(final, false);
  };

  const onSliderLoanAmountChange = ([value]: number[]) => {
    const sliderValue =
      currentSlider === "default"
        ? LoanAmountValues()!.default[value]
        : LoanAmountValues()!.extended[value];

    setSliderValue(sliderValue);
    requestedLoanAmount(sliderValue, false);
  };

  return (
    <div>
      {!isPreliminaryBid && (
        <AmountSliderSelector
          currentSlider={currentSlider}
          isRealEstate={isRealEstate}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setCurrentSlider(e.target.value);
          }}
        />
      )}

      <Slider
        id="loan-amount-slider"
        label={label ?? t("applicationForm.commons.requestedAmountPreLabel")}
        name={name ?? "requested_loan_amount"}
        htmlFor="requestedLoanAmount"
        labelClassName="tw-items-center"
        onSliderChange={onSliderLoanAmountChange}
        onValueChange={onInputLoanAmountChange}
        value={sliderValue < 0 ? 0 : sliderValue}
        values={sliderValues}
        formatter={(valueForFormat: string | number) =>
          customFormatCurrency(valueForFormat, formattedCurrencyValue)
        }
        inputFormatter={FormatNumber}
        placeholder={t(
          "applicationForm.commons.exactRequestedAmountPlaceholder",
          {
            currency,
          },
        )}
        postfix={value !== 0 ? currencyValue : ""}
        isCustomInput={isCustomRequestedLoan}
        onCustomToggle={() => onRequestedLoanToggle(currentSlider)}
        error={touched && errors ? errors : ""}
        inputOnBlur={onInputBlur}
      />
    </div>
  );
};

export default AmountSlider;
