import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// import CommonTransEN from "@krea/customer-features/translations/common/en.json";
// import CommonTransFI from "@krea/customer-features/translations/common/fi.json";
import CommonTransSE from "@krea/customer-features/translations/common/sv.json";

// import WidgetTransEN from "./translations/en.json";
// import WidgetTransFI from "./translations/fi.json";
import WidgetTransSE from "./translations/sv.json";

// Disable localStorage usage for language detection and rely on cookies instead
// localStorage is not available in some browsers some times and causes crashes.

i18n.use(initReactI18next).init({
  resources: {
    sv: {
      widget: WidgetTransSE,
      common: CommonTransSE,
    },
    // en: {
    //   widget: WidgetTransEN,
    //   common: CommonTransEN,
    // },
    // fi: {
    //   widget: WidgetTransFI,
    //   common: CommonTransFI,
    // },
  },
  lng: "sv",
  fallbackLng: "sv",
  supportedLngs: [
    "sv",
    // "en",
    // "fi"
  ],
  ns: ["widget", "common"],
  defaultNS: "widget",
  fallbackNS: "common",
  interpolation: {
    escapeValue: false,
  },
  react: {
    transKeepBasicHtmlNodesFor: ["br", "strong", "i", "p", "ul", "li", "a"],
  },
});

export default i18n;
