import { createRoot, Root } from "react-dom/client";

import { Widget, WidgetProps } from "./Widget";
import settings, { setCountryCode } from "./settings";
import i18n from "./i18n";

declare global {
  interface Window {
    KA_Widget: {
      _root?: Root;
      init: (elementId: string, props: WidgetProps) => void;
      unmount: () => void;
    };
  }
}
let root: undefined | Root;

window.KA_Widget = {
  init: (elementId: string, props: WidgetProps) => {
    const el = document.getElementById(elementId);

    if (!el) {
      console.error(`Element with ID '${elementId}' could not be found.`);

      return;
    }
    setCountryCode(props.countryCode);
    i18n.changeLanguage("sv");

    root = createRoot(el);
    root.render(<Widget {...props} />);
  },
  unmount: () => {
    if (root) {
      root.unmount();
    }
  },
};
