import { ReactNode } from "react";
import SVG from "react-inlinesvg";

import Check from "@krea/customer-features/images/svg/check.svg";
import Cross from "@krea/customer-features/images/svg/cross-mark.svg";
import Button from "@krea/customer-features/shared-components/button";
import twMerge from "@krea/customer-features/utils/tailwind-utils";

export const ResultContent = ({
  headingContent,
  children,
  backButton,
  probability,
  probabilityOptions,
  isEdgeCase,
}: {
  headingContent: ReactNode;
  backButton?: {
    onBack: () => void;
    buttonText: string;
  };
  children: ReactNode;
  probability: string | null;
  probabilityOptions: Record<string, string>;
  isEdgeCase: boolean | undefined;
}) => {
  return (
    <>
      <div
        className={twMerge(
          "tw-border tw-border-solid tw-border-mint-500 tw-rounded-xl tw-mb-7 tw-bg-white",
          {
            "tw-border-mint-500":
              probability === probabilityOptions.high ||
              probability === probabilityOptions.medium,
            "tw-border-orange-500": probability === probabilityOptions.low,
          },
        )}
      >
        <div
          className={twMerge(
            "tw-rounded-t-xl tw-py-4 tw-px-5 tw-justify-between",
            {
              "tw-bg-mint-100": probability === probabilityOptions.high,
              "tw-bg-orange-50": probability === probabilityOptions.low,
              "lg:tw-flex": !isEdgeCase,
            },
          )}
        >
          <div className="tw-flex tw-items-center">
            <div
              className={twMerge(
                "tw-h-[15px] tw-w-[15px] tw-shrink-0 tw-rounded-full tw-items-center tw-flex tw-justify-center",
                {
                  "tw-bg-orange-300": probability === probabilityOptions.low,
                  "tw-bg-mint-500":
                    probability === probabilityOptions.high ||
                    probability === probabilityOptions.medium,
                },
              )}
            >
              <SVG
                src={probability === probabilityOptions.low ? Cross : Check}
                className="tw-h-[9px] tw-w-[9px]"
              />
            </div>

            <div className="tw-m-0 tw-ml-[15px] tw-p-0">{headingContent}</div>
          </div>

          {backButton && (
            <Button
              onClick={backButton.onBack}
              variant="text"
              size="sm"
              className={"tw-ml-[30px] tw-font-bold"}
            >
              {backButton.buttonText}
            </Button>
          )}
        </div>
        <div className="tw-px-5 tw-pt-4">{children}</div>
      </div>
    </>
  );
};
