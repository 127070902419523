import React, { ComponentProps, PropsWithChildren } from "react";
import Tooltip from "react-tooltip-lite";

import styles from "./Tooltip.module.scss";

// TODO: Decide whether to use this or use the @shared/design-system Tooltip
const AppTooltip = ({
  className,
  children,
  content,
  ...rest
}: PropsWithChildren<ComponentProps<typeof Tooltip>>) => {
  return (
    // @ts-expect-error -- for some reason, Tooltip does not accept children on their types but they do on their docs
    <Tooltip
      className={className}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      tipContentClassName={styles.root}
      content={content}
      padding="0.875rem"
      zIndex={20001}
      {...rest}
    >
      {children}
    </Tooltip>
  );
};

export default AppTooltip;
