import { useTranslation } from "react-i18next";
import SVG from "react-inlinesvg";

import TrustPilotColorSVG from "@krea/customer-features/images/svg/trustpilot/trustpilot-star-color.svg";
import { Text } from "@krea/customer-features/shared-components/text";
import { twMerge } from "@krea/shared/design-system/tailwind-utils";
import { useGetReviewProviderScores } from "@krea/customer-features/store/cms-site-settings/hooks";
import { ReviewProviderEnum } from "@krea/customer-features/types/reviewProvider";

const trustpilotText = (language: string) => {
  switch (true) {
    case language === "sv":
      return { rating: "Utmärkt", reviews: "omdömen" };
    case language === "en":
      return { rating: "Excellent", reviews: "reviews" };
    case language === "fi":
      return { rating: "Erinomainen", reviews: "arvostelua" };
    default:
      return { rating: "Excellent", reviews: "reviews" };
  }
};

interface IContactsTrustpilotProps {
  className?: string;
  isSimpleVersion?: boolean;
}

const ContactsTrustpilot = ({
  className,
  isSimpleVersion,
}: IContactsTrustpilotProps) => {
  const { i18n } = useTranslation();

  const data = useGetReviewProviderScores(ReviewProviderEnum.TRUSTPILOT);
  const score = `${data?.score} / ${data?.maxScore}`;
  const numberOfReviews = `${data?.numberOfReviews}`;
  const linkToReviews = data?.linkToProviderReviews
    ? data.linkToProviderReviews
    : "https://www.trustpilot.com/review/krea.se";

  if (!data) {
    return null;
  }

  return (
    <a
      href={linkToReviews}
      target="_blank"
      style={{ textDecoration: "none", color: "inherit" }}
      rel="noreferrer"
    >
      <div className={twMerge("tw-flex tw-items-center", className)}>
        <Text
          size="sm"
          className="tw-mr-2 tw-shrink-0 tw-text-nowrap tw-font-bold"
        >
          {`${trustpilotText(i18n.language).rating} ${score}`}
        </Text>

        <div
          className={twMerge([
            "tw-relative tw-w-6",
            "before:tw-block before:tw-content-[''] before:tw-pb-[100%]",
          ])}
        >
          <SVG
            src={TrustPilotColorSVG}
            className="tw-absolute tw-left-0 tw-top-0 tw-h-auto tw-w-full"
          />
        </div>
        <Text
          size="sm"
          className="tw-ml-1 tw-shrink-0 tw-text-nowrap tw-font-bold"
        >
          Trustpilot
        </Text>
        {!isSimpleVersion && (
          <>
            <Text
              size="sm"
              className="tw-mx-2 tw-font-bold"
              style={{ opacity: "0.6" }}
            >
              |
            </Text>

            <Text
              size="sm"
              className="tw-shrink-0 tw-text-nowrap tw-font-bold"
              style={{ opacity: "0.6" }}
            >
              {`${numberOfReviews} ${trustpilotText(i18n.language).reviews}`}
            </Text>
          </>
        )}
      </div>
    </a>
  );
};

export default ContactsTrustpilot;
