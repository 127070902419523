import { yupResolver } from "@hookform/resolvers/yup";
import CloudLoader from "@krea/customer-features/images/CloudLoader.gif";
import { getCompanyBrandName } from "@krea/shared/utils";
import axios, { AxiosError } from "axios";
import classNames from "classnames";
import { parseIncompletePhoneNumber } from "libphonenumber-js";
import React, { useState } from "react";
import { useController, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useShallow } from "zustand/react/shallow";

import { ConsentCheckboxValidation } from "@krea/customer-features/form-validation/ConsentCheckboxValidation";
import { EmailValidation } from "@krea/customer-features/form-validation/EmailValidation";
import { OrganisationValidation } from "@krea/customer-features/form-validation/OrganisationValidation";
import { MobilePhoneNumberValidation } from "@krea/customer-features/form-validation/PhoneNumberValidation";
import { RequestedLoanAmountValidation } from "@krea/customer-features/form-validation/RequestedLoanAmountValidation";
import { useMobile } from "@krea/customer-features/hooks";
import { ICustomException } from "@krea/customer-features/models/commonInterfaces";
import { settings } from "@krea/customer-features/settings";
import Button from "@krea/customer-features/shared-components/button";
import AmountSlider from "@krea/customer-features/shared-components/loan-application-form/fields/amount-slider";
import EmailInput from "@krea/customer-features/shared-components/loan-application-form/fields/email";
import OrganisationInput from "@krea/customer-features/shared-components/loan-application-form/fields/organisation-number";
import PhoneInput from "@krea/customer-features/shared-components/loan-application-form/fields/phone";
import { Text } from "@krea/customer-features/shared-components/text";
import {
  IPreliminaryLoanAmountData,
  useLoanApplicationFormStore,
} from "@krea/customer-features/store/loan-application-form/hooks";
import { privacyPolicyLink } from "@krea/customer-features/utils";
import { CountryCode } from "@krea/shared/types/common";
import { Check } from "lucide-react";

import CheckBox from "@krea/shared/design-system/fields/Checkbox";
import { twMerge } from "@krea/shared/design-system/tailwind-utils";
import { WidgetProps } from "../../Widget";
import { AmountEstimatorResult } from "./AmountEstimatorResult";
import { useCustomerFeaturesSettingsStore } from "@krea/customer-features/store/app-settings/useCustomerFeaturesSettingsStore";

const getValidationSchema = (countryCode: CountryCode) => {
  return Yup.object().shape({
    organisation: OrganisationValidation(),
    email: EmailValidation(),
    mobilePhoneNumber: MobilePhoneNumberValidation(countryCode),
    consentCheckbox: ConsentCheckboxValidation(),
    requestedLoanAmount: RequestedLoanAmountValidation(),
  });
};

type ValidationSchemaType = Yup.InferType<
  ReturnType<typeof getValidationSchema>
>;

export const AmountEstimatorWidgetSchema = Yup.object({
  form: Yup.object({
    title: Yup.string().optional(),
    benefitText: Yup.string().optional(),
    ctaText: Yup.string().optional(),
  }).optional(),
  result: Yup.object({
    title: Yup.string().optional(),
  }).optional(),
}).required(
  "Amount Estimator object is required to render the widget, the options are optional",
);

type AmountEstimatorWidgetProps = Yup.InferType<
  typeof AmountEstimatorWidgetSchema
>;

export const AmountEstimatorWidget = ({
  form,
  result,
  utmSourceIdentifier,
  utmCampaign,
}: AmountEstimatorWidgetProps &
  Pick<WidgetProps, "utmSourceIdentifier" | "utmCampaign">) => {
  const countryCode = useCustomerFeaturesSettingsStore(
    (state) => state.countryCode,
  );
  const { t, i18n } = useTranslation();
  const [isSubmitting, setSubmitting] = useState(false);

  const { title: formTitle, benefitText, ctaText } = form ?? {};

  const { title: resultTitle } = result ?? {};

  const isMobile = useMobile();

  const {
    organisation: { name: organisationName, organisationNumber },
    email,
    mobilePhoneNumber,
    requestedLoanAmount,
    consentCheckbox,
    setOrganisation,
    setEmail,
    setMobilePhoneNumber,
    setRequestedLoanAmount,
    setConsentCheckbox,
    preliminaryBid,
    setPreliminaryBid,
    removeFormError,
  } = useLoanApplicationFormStore(
    useShallow((state) => ({
      organisation: state.organisation,
      email: state.email,
      mobilePhoneNumber: state.mobilePhoneNumber,
      requestedLoanAmount: state.requestedLoanAmount,
      consentCheckbox: state.consentCheckbox,
      setOrganisation: state.setOrganisation,
      setEmail: state.setEmail,
      setMobilePhoneNumber: state.setMobilePhoneNumber,
      setRequestedLoanAmount: state.setRequestedLoanAmount,
      setConsentCheckbox: state.setConsentCheckbox,
      setPreliminaryBid: state.setPreliminaryBid,
      preliminaryBid: state.preliminaryBid,
      removeFormError: state.removeFormError,
      utmCampaign: state.utmCampaign,
    })),
  );

  const [showResult, setShowResult] = useState(!!preliminaryBid);

  const { control, handleSubmit, getValues, setValue, setError, formState } =
    useForm<ValidationSchemaType>({
      mode: "onChange",
      defaultValues: {
        organisation: {
          name: organisationName,
          organisationNumber: organisationNumber,
        },
        email,
        mobilePhoneNumber,
        requestedLoanAmount,
        consentCheckbox,
      },
      resolver: yupResolver(getValidationSchema(countryCode)),
    });

  const onSubmit = async () => {
    setSubmitting(true);

    const startTime = Date.now();
    // TODO: Add utm data to the payload
    const utmData: Record<string, string> = {
      utm_source: utmSourceIdentifier,
      utm_medium: "widget",
    };
    if (utmCampaign) {
      utmData.utm_campaign = utmCampaign;
    }

    const payload = {
      countryCode: countryCode,
      email,
      mobilePhoneNumber,
      requestedLoanAmount,
      organisationName,
      organisationNumber,
      utmData: JSON.stringify(utmData),
    };

    try {
      const response = await axios.post<IPreliminaryLoanAmountData>(
        `${settings.backendBaseUrl}/api/v1/preliminary-loan-amount`,
        payload,
      );

      const finishTime = Date.now();
      const timeDiff = finishTime - startTime;

      // setStatus({ success: true });
      // setStatus({ success: true });

      if (timeDiff >= 3000) {
        setSubmitting(false);
      } else {
        setTimeout(() => {
          setSubmitting(false);
        }, 3000 - timeDiff);
      }

      setOrganisation({
        name: organisationName,
        organisationNumber: organisationNumber,
      });
      setEmail(email);
      setMobilePhoneNumber(parseIncompletePhoneNumber(mobilePhoneNumber));
      setRequestedLoanAmount(
        response.data.useEdgeCaseLogic
          ? requestedLoanAmount
          : response.data.estimatedMaxLoanAmount,
      );
      setPreliminaryBid(response.data);
      setShowResult(true);
    } catch (error: unknown) {
      const emailVerificationError = error as
        | AxiosError<ICustomException>
        | undefined;

      if (
        emailVerificationError?.response?.data.errors.find(
          (error) => error.code === "1006",
        )
      ) {
        const translatedErrorMessage = t(
          "applicationForm.commons.emailErrors.validationError",
        );

        setError("email", { message: translatedErrorMessage });
      }
      //setStatus({ success: false });
      setSubmitting(false);
    }
  };

  const {
    field: organisationNumberField,
    fieldState: organisationNumberFieldState,
  } = useController({
    name: "organisation.organisationNumber",
    control,
  });

  const { field: emailField, fieldState: emailFieldState } = useController({
    name: "email",
    control,
    defaultValue: "",
  });

  const { field: phoneNumberField, fieldState: phoneNumberFieldState } =
    useController({
      name: "mobilePhoneNumber",
      control,
    });

  const { field: consentCheckboxField, fieldState: consentCheckboxFieldState } =
    useController({
      name: "consentCheckbox",
      control,
      defaultValue: false,
    });

  const {
    field: { value, onChange: onInputChange, onBlur: onInputBlur },
    fieldState,
  } = useController({
    name: "requestedLoanAmount",
    control,
  });

  const onChange = (name: string, value: number) => {
    onInputChange(value);
    setRequestedLoanAmount(value);
  };

  let formTitleText = t("amountEstimatorWidget.preliminaryLoanAmountHeading");
  if (formTitle !== undefined) {
    formTitleText = formTitle;
  }

  let resultTitleText = t("amountEstimatorWidget.yourEstimatedCreditLimit");
  if (resultTitle !== undefined) {
    resultTitleText = resultTitle;
  }

  let submitButtonText = t("amountEstimatorWidget.estimateLimit");
  if (ctaText) {
    submitButtonText = ctaText;
  }

  let benefitTextToUse =
    countryCode === CountryCode.SE
      ? t("amountEstimatorWidget.immediateResponseWithoutCreditCheck")
      : t("amountEstimatorWidget.immediateResponse");

  if (benefitText !== undefined) {
    benefitTextToUse = benefitText;
  }

  let isLimitedCompany = false;

  if (
    organisationName &&
    !organisationNumberFieldState.error &&
    organisationNumberFieldState.isTouched
  ) {
    const organisationSplitName = organisationName.toLowerCase().split(" ");

    switch (countryCode) {
      case CountryCode.SE:
        isLimitedCompany =
          organisationName.replace(" ", "") !== "ab" &&
          organisationSplitName.includes("ab");
        break;
      case CountryCode.FI:
        isLimitedCompany =
          organisationName.replace(" ", "") !== "oy" &&
          organisationSplitName.includes("oy");
        break;
      default:
        isLimitedCompany = false;
    }
  }

  const onContinueToApply = () => {
    const formValues = getValues();
    const { requestedLoanAmount } = useLoanApplicationFormStore.getState();
    const params: Record<string, string> = {
      amount: requestedLoanAmount.toString(),
      orgNumber: formValues.organisation.organisationNumber,
      email: formValues.email,
      phone: formValues.mobilePhoneNumber,
      consent: formValues.consentCheckbox ? "true" : "false",
    };
    if (utmSourceIdentifier) {
      // utmSourceIdentifier is required but can be "" (empty string) if we use it intenrally
      params.utm_source = utmSourceIdentifier;
      params.utm_medium = "widget";

      if (utmCampaign) {
        params.utm_campaign = utmCampaign;
      }
    }

    const urlParams = new URLSearchParams(params).toString();

    const url = `${settings.customerWebUrl}/application?${urlParams}`;
    console.log(url);

    window.open(url, "_blank");
  };

  if (showResult) {
    return (
      <>
        <h3 className="tw-mb-6 tw-text-2xl md:tw-text-3xl tw-font-semibold tw-text-black-500">
          {resultTitleText}
        </h3>
        <AmountEstimatorResult
          onGoBack={() => {
            setShowResult(false);
            setPreliminaryBid(undefined);
          }}
          onContinueToApply={onContinueToApply}
          utmSourceIdentifier={utmSourceIdentifier}
        />
      </>
    );
  }
  return (
    <div>
      {isSubmitting && (
        <div>
          <div className="tw-mt-7 tw-flex tw-h-3/4 tw-w-full tw-flex-col tw-items-center">
            <div className="tw-m-0 tw-text-center tw-text-[1.5rem]">
              {i18n.t("amountEstimatorWidget.loadingText")}
            </div>
            <div className={"tw-inset-0 tw-w-full tw-py-5"}>
              <img
                className="tw-relative tw-mx-auto tw-h-auto tw-w-[160px] tw-object-none tw-rounded-full"
                src={CloudLoader}
                alt="Loader"
              />
            </div>
          </div>
        </div>
      )}

      {!isSubmitting && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <h3 className="tw-text-2xl md:tw-text-3xl tw-font-semibold tw-text-black-500">
            {formTitleText}
          </h3>

          {benefitTextToUse && (
            <div className="tw-inline-block tw-my-4">
              <div className="tw-flex tw-items-center tw-rounded-xl tw-border tw-border-solid tw-border-mint-500 tw-bg-mint-100 tw-px-3 tw-py-1">
                <div className="tw-flex tw-h-[15px] tw-w-[15px] tw-items-center tw-justify-center tw-rounded-full tw-bg-mint-500">
                  <Check
                    strokeWidth={5}
                    className="tw-text-white tw-h-auto tw-w-[9px]"
                  />
                </div>

                <Text size="sm" className="tw-ml-[8px]">
                  {benefitTextToUse}
                </Text>
              </div>
            </div>
          )}

          <div className="tw-mt-2">
            <OrganisationInput
              value={organisationNumberField.value}
              error={
                formState.errors.organisation?.organisationNumber?.message ??
                formState.errors.organisation?.name?.message
              }
              touched={organisationNumberFieldState.isTouched}
              onCompanyChange={(company) => {
                setValue(
                  "organisation.organisationNumber",
                  company.organisationNumber,
                  {
                    shouldTouch: true,
                    shouldDirty: true,
                    shouldValidate: true,
                  },
                );
                setValue("organisation.name", company.name, {
                  shouldTouch: true,
                  shouldDirty: true,
                  shouldValidate: true,
                });

                setOrganisation(company);
              }}
              onBlur={organisationNumberField.onBlur}
              isLimitedCompany={isLimitedCompany}
            />
          </div>
          <div className="tw-mx-1 tw-mt-7">
            <AmountSlider
              name={"requestedLoanAmount"}
              value={value}
              onInputBlur={onInputBlur}
              setInputValue={onChange}
              errors={fieldState.error?.message}
              touched={fieldState.isTouched}
              isRealEstate={false}
            />
          </div>
          <div className="tw-mt-5">
            <EmailInput
              label={t("applicationForm.commons.emailLabel", {
                ns: "common",
              })}
              value={emailField.value}
              errors={emailFieldState.error?.message}
              touched={
                emailFieldState.error?.message || emailFieldState.isTouched
              }
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                emailField.onChange(e.target.value);
                setEmail(e.target.value);
                removeFormError("email");
              }}
              onBlur={emailField.onBlur}
            />
          </div>

          <div className="tw-mb-4 tw-mt-5">
            <PhoneInput
              id="PreliminaryBidFormMobilePhoneNumber"
              label={t("applicationForm.commons.phoneLabel", {
                ns: "common",
              })}
              value={phoneNumberField.value}
              touched={phoneNumberFieldState.isTouched}
              errors={phoneNumberFieldState.error?.message}
              handleChange={(e) => {
                setMobilePhoneNumber(e.target.value);
                phoneNumberField.onChange(e.target.value);
              }}
              onAbort={phoneNumberField.onBlur}
            />
          </div>

          <CheckBox
            onChange={() => {
              setConsentCheckbox(!consentCheckbox);
              consentCheckboxField.onChange(!consentCheckboxField.value);
            }}
            checked={consentCheckboxField.value}
            error={consentCheckboxFieldState.error?.message}
            className="tw-my-7"
            label={
              <Trans
                i18nKey={"amountEstimatorWidget.privacyPolicyConsent"}
                values={{
                  company: getCompanyBrandName(countryCode),
                }}
                components={{
                  1: (
                    <a
                      target="_blank"
                      href={privacyPolicyLink(
                        countryCode,
                        settings.countryWebsiteUrl,
                        i18n.language,
                      )}
                      rel="noopener noreferrer"
                      className="tw-font-bold tw-text-primary tw-no-underline"
                    />
                  ),
                }}
              />
            }
            labelClassName="tw-font-normal tw-text-sm"
            onBlur={consentCheckboxField.onBlur}
          />

          <Button
            block={true}
            size="lg"
            tabIndex={0}
            disabled={!formState.isValid}
            className={twMerge(
              "tw-text-center tw-mb-6 tw-w-full tw-bg-cta hover:tw-bg-cta/90",
            )}
            onClick={() => {}}
            type="submit"
            variant={"contained"}
          >
            {submitButtonText}
          </Button>
        </form>
      )}
    </div>
  );
};
