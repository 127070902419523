import { KeyboardEventHandler, PropsWithChildren } from "react";

import { Text } from "@krea/customer-features/shared-components/text";
import twMerge from "@krea/customer-features/utils/tailwind-utils";

interface ILabelProps {
  htmlFor?: string;
  className?: string;
  disabled?: boolean;
  tabIndex?: number;
  onClick?: () => void;
  onKeyDown?: KeyboardEventHandler<HTMLElement>;
}

export const Label = ({
  children,
  htmlFor,
  className,
  disabled,
  tabIndex,
  onClick,
  onKeyDown,
}: PropsWithChildren<ILabelProps>) => {
  return (
    <Text
      htmlTag="label"
      tabIndex={tabIndex}
      size="sm"
      className={twMerge(
        "tw-inline-block tw-font-semibold tw-leading-6 tw-m-0",
        className,
      )}
      aria-disabled={disabled}
      htmlFor={htmlFor}
      onClick={onClick}
      onKeyDown={onKeyDown}
    >
      {children}
    </Text>
  );
};
