import { useQuery } from "@tanstack/react-query";
import {
  ISiteSettings,
  IReviewProviderScore,
} from "@krea/customer-features/types/cmsSiteSettings";
import { CMS_SITE_SETTINGS_STORE_KEY } from "@krea/customer-features/store/queryClient";
import { CMS_SITE_SETTINGS_FRAGMENT } from "@krea/customer-features/graphql/cms-site-settings/cms-site-settings.fragment";
import { settings } from "@krea/customer-features/settings";
import { ReviewProviderEnum } from "@krea/customer-features/types/reviewProvider";
import { client } from "@krea/customer-features/graphql";

export const useGetCmsSiteSettings = () => {
  const siteHostnameObject = new URL(settings.countryWebsiteUrl);
  const siteHostname =
    siteHostnameObject.hostname +
    (siteHostnameObject.port ? `:${siteHostnameObject.port}` : "");

  return useQuery<ISiteSettings>({
    queryKey: [CMS_SITE_SETTINGS_STORE_KEY, siteHostname],
    queryFn: async () => {
      const query = `
      query GetCmsSiteSettings($siteHostname: String!) {
        cmsSiteSettings(siteHostname: $siteHostname) {
          ...CMSSiteSettingsFragment
        }
      }
      ${CMS_SITE_SETTINGS_FRAGMENT}
      `;

      const response = await client.request<{ cmsSiteSettings: ISiteSettings }>(
        query,
        { siteHostname },
      );

      return response.cmsSiteSettings;
    },
    staleTime: Infinity,
  });
};

export const useGetReviewProviderScores = (
  providerName: ReviewProviderEnum,
) => {
  const { data } = useGetCmsSiteSettings();
  const reviewProviderList = data?.reviewProviderScores;

  if (reviewProviderList) {
    const reviewProviderMap = data.reviewProviderScores.reduce(
      (acc, provider) => {
        acc[provider.provider] = provider;
        return acc;
      },
      {} as Record<string, IReviewProviderScore>,
    );

    return reviewProviderMap[providerName];
  }

  return null;
};
