import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import {
  ICompanySearchItem,
  useCompanySearch,
} from "@krea/customer-features/shared-components/loan-application-form/fields/organisation-number/useCompanySearch";
import { Text } from "@krea/customer-features/shared-components/text";
import { useCustomerFeaturesSettingsStore } from "@krea/customer-features/store/app-settings/useCustomerFeaturesSettingsStore";
import { orgNumValidator, TEST_IDS } from "@krea/customer-features/utils";
import twMerge from "@krea/customer-features/utils/tailwind-utils";
import { CountryCode } from "@krea/shared/types/common";

import TextInput from "../../../fields/input";

export interface IFormInput {
  name: string;
  organisationNumber: string;
}

interface IProps {
  value: string;
  touched?: boolean;
  error?: string;
  label?: string;
  setInputValue?: (key: string, value: string) => void;
  onCompanyChange: (organisation: IFormInput) => void;
  isLimitedCompany: boolean;
  onBlur: () => void;
}

const OrganisationNumberInput = ({
  value,
  touched,
  error,
  label,
  onCompanyChange,
  isLimitedCompany,
  onBlur,
}: IProps) => {
  const countryCode = useCustomerFeaturesSettingsStore(
    (state) => state.countryCode,
  );

  const { t } = useTranslation();

  const { data, isFetching } = useCompanySearch(value);
  const { results, hasDuplicates } = data ?? {};

  const matchedCompany = results?.find(
    (it) => it.organisation_number === value.replace(/-/g, ""),
  );
  const prevMatchedCompanyRef = useRef<ICompanySearchItem | undefined>(
    undefined,
  );

  const [isFocusedOnSearch, setIsFocusedOnSearch] = useState(false);

  const companyResultsItems = useRef<HTMLLIElement[]>([]);
  const companyInputRef = useRef<HTMLInputElement>();
  const resultsRef = useRef<HTMLDivElement>();

  const handeSearchFocusOrBlur = (e: MouseEvent) => {
    // This removed focus (so dropdown is open) on click outside the search input and results.

    const isFocusedOnSearch =
      (companyInputRef.current &&
        companyInputRef.current.contains(e.target as HTMLInputElement)) ||
      (resultsRef.current &&
        resultsRef.current.contains(e.target as HTMLDivElement)) ||
      false;

    setIsFocusedOnSearch(isFocusedOnSearch);
  };

  useEffect(() => {
    document.addEventListener("click", handeSearchFocusOrBlur);

    return () => {
      document.removeEventListener("click", handeSearchFocusOrBlur);
    };
  }, []);

  const formatOrgNumToPost = useCallback(
    (organisationNumber: string) => {
      let orgNumToFormat = organisationNumber;

      if (orgNumToFormat) {
        const orgNumData = orgNumValidator(orgNumToFormat, countryCode);

        if (orgNumData.isValid) {
          switch (countryCode) {
            case CountryCode.SE:
              orgNumToFormat = orgNumData.compact;
              break;
            case CountryCode.FI:
              orgNumToFormat = orgNumData.formatted;
              break;
          }
        }
      }

      return orgNumToFormat;
    },
    [countryCode],
  );

  const selectCompany = useCallback(
    (company: ICompanySearchItem) => {
      onCompanyChange({
        name: company.company_name,
        organisationNumber: formatOrgNumToPost(company.organisation_number),
      });
    },
    [onCompanyChange, formatOrgNumToPost],
  );

  const onChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const companyInputValue = formatOrgNumToPost(target.value ?? "");

    const companyValue: IFormInput = {
      name: companyInputValue,
      organisationNumber: companyInputValue,
    };
    onCompanyChange(companyValue);
  };

  useEffect(() => {
    // If the matchedCompany has updated and we have an orgNum match,
    // we want to trigger the onCompanyChange with the company name from the result.
    if (
      prevMatchedCompanyRef.current !== matchedCompany &&
      matchedCompany?.organisation_number === value.replace(/-/g, "")
    ) {
      selectCompany(matchedCompany);
    }

    // Store the matchedCompany in a ref to compare it in the next render.
    prevMatchedCompanyRef.current = matchedCompany;

    // We explicitly don't want to run this when the value changes (not in dependency array).
    // Because this function checks "after" the value has changed if a match in the results was found.

    // return () => {
    //   prevMatchedCompanyRef.current = undefined;
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectCompany, matchedCompany]);

  const onCompanyReset = () => {
    setIsFocusedOnSearch(true);
    onCompanyChange({ name: "", organisationNumber: "" });

    const resetFunc = async () => {
      const inputOnFocus = () => {
        if (companyInputRef.current) {
          setTimeout(() => companyInputRef.current?.focus(), 0);
        }
      };

      await inputOnFocus();
    };
    resetFunc();
  };

  const onCompanyInputKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    const { 0: firstItem } = companyResultsItems.current;

    switch (event.key) {
      case "Tab":
        if (event.shiftKey || !firstItem) {
          setIsFocusedOnSearch(false);
        }

        break;
      case "ArrowDown":
        event.preventDefault();

        if (firstItem) {
          firstItem.focus();
        }

        break;
      default:
        break;
    }
  };

  const onCompanyItemKeyDown = (event: React.KeyboardEvent<HTMLLIElement>) => {
    const index = companyResultsItems.current.indexOf(
      event.target as HTMLLIElement,
    );

    const resultsLength = results?.length ?? 0;

    const goToNextItem = () => {
      const nextIndex = index + 1;

      if (resultsLength > nextIndex) {
        event.preventDefault();
        companyResultsItems.current[index + 1].focus();
      }
    };

    const goToPreviousItem = () => {
      event.preventDefault();

      const previousIndex = index - 1;

      if (previousIndex === -1) {
        // go back up to the input
        // from "this" "top" li item.
        companyInputRef.current?.querySelector("input")?.focus();
      } else {
        companyResultsItems.current[previousIndex].focus();
      }
    };

    switch (event.key) {
      case "ArrowDown":
        goToNextItem();
        break;
      case "ArrowUp":
        goToPreviousItem();
        break;
      case "Enter":
        if (results !== undefined) {
          selectCompany(results[index]);
        }
        break;
      default:
        break;
    }
  };

  const setFocusedOnSearch = () => {
    setIsFocusedOnSearch(true);
  };

  return (
    <TextInput
      data-test-id={TEST_IDS.common.forms.organizationNumberInput}
      tabIndex={0}
      size="lg"
      type="text"
      name="organisationNumber"
      id="company"
      ref={(ref) => {
        if (ref) {
          companyInputRef.current = ref;
        }
      }}
      onChange={onChange}
      onBlur={onBlur}
      value={
        matchedCompany && value
          ? `${matchedCompany.company_name} (${orgNumValidator(matchedCompany.organisation_number, countryCode).formatted})`
          : value || ""
      }
      placeholder={t(
        `applicationForm.commons.companyNamePlaceholder.${countryCode}`,
      )}
      label={label ?? t("applicationForm.commons.companyNameLabel")}
      onFocus={setFocusedOnSearch}
      readOnly={!!matchedCompany}
      onKeyDown={onCompanyInputKeyDown}
      error={!isFocusedOnSearch && touched && error ? error : undefined}
      confirmation={
        isLimitedCompany
          ? t("applicationForm.common.limitedCompanyConfirmation")
          : undefined
      }
      autoComplete="off"
      textAppend={
        matchedCompany && (
          <Text
            size="sm"
            role="button"
            className="tw-z-50 tw-cursor-pointer tw-rounded tw-bg-primary-150 tw-px-2 tw-py-1 tw-font-bold tw-text-primary"
            onClick={onCompanyReset}
            aria-label={t("applicationForm.commons.companyNameChange")}
            tabIndex={0}
          >
            {t("applicationForm.commons.companyNameChange")}
          </Text>
        )
      }
      autoselect={
        isFocusedOnSearch &&
        value &&
        value.length > 0 &&
        !matchedCompany && (
          <div
            className="tw-absolute tw-top-full tw-z-[3] tw-mt-4 tw-w-full tw-cursor-pointer tw-overflow-hidden tw-rounded tw-border tw-bg-white tw-shadow-md"
            ref={(ref) => {
              if (ref) {
                resultsRef.current = ref;
              }
            }}
          >
            <ul
              role="listbox"
              tabIndex={0}
              className="tw-m-0 tw-max-h-[152px] tw-list-none tw-overflow-x-hidden tw-overflow-y-scroll tw-p-0"
            >
              {results?.map((r, index) => (
                <li
                  role="option"
                  aria-selected="false"
                  tabIndex={0}
                  className="tw-relative tw-flex tw-cursor-pointer tw-justify-between tw-overflow-hidden tw-text-ellipsis tw-bg-white tw-px-4 tw-py-2
                    tw-text-[0px] tw-font-normal tw-leading-[0px] tw-outline-none hover:tw-bg-primary hover:tw-text-white focus:tw-bg-primary focus:tw-text-white"
                  onKeyDown={onCompanyItemKeyDown}
                  ref={(ref) => {
                    if (ref) {
                      companyResultsItems.current[index] = ref;
                    }
                  }}
                  key={`${r.company_name}-${index}`}
                  onClick={() => selectCompany(r)}
                  data-company-name={r.company_name}
                  aria-label={r.company_name}
                  data-test-id={TEST_IDS.common.forms.companyResult(index)}
                >
                  <div className="tw-w-full tw-text-ellipsis tw-pr-4 tw-pt-1 tw-text-base tw-font-semibold">
                    {r.company_name}
                  </div>
                  <Text
                    size="sm"
                    className="
                      tw-shrink-0 tw-rounded tw-bg-white tw-px-2 tw-py-1 tw-font-bold tw-text-gray-500
                      hover:tw-text-primary
                      "
                  >
                    {t("applicationForm.commons.companyNameSelect")}
                  </Text>
                </li>
              ))}
            </ul>
            {isFetching && (
              <div className="tw-p-3 tw-text-center tw-font-semibold tw-text-gray-600">
                {t("applicationForm.commons.companyNamePreloader")}.
              </div>
            )}
            {!isFetching && (
              <div
                className={twMerge(
                  "tw-border-t tw-border-gray-300 tw-bg-white tw-font-semibold tw-text-gray-600 tw-transition tw-ease-in-out tw-duration-300 tw-p-3 tw-text-center",
                  hasDuplicates &&
                    "tw-bg-orange-300 tw-border-orange-300 tw-text-black",
                )}
              >
                {t("applicationForm.commons.companyNameFindHelp")}
              </div>
            )}
          </div>
        )
      }
    />
  );
};

export default OrganisationNumberInput;
