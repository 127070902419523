import * as SliderPrimitive from "@radix-ui/react-slider";
import * as React from "react";

import { twMerge } from "./tailwind-utils";

const Slider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({ className, ...props }, ref) => (
  <SliderPrimitive.Root
    ref={ref}
    className={twMerge(
      "tw-relative tw-flex tw-w-full tw-touch-none tw-select-none tw-items-center",
      className,
    )}
    {...props}
  >
    <SliderPrimitive.Track className="tw-relative tw-h-[14px] tw-w-full tw-grow tw-cursor-pointer tw-overflow-hidden tw-rounded-full tw-bg-primary-150 tw-transition-colors tw-duration-300  tw-ease-in-out hover:tw-bg-primary/10 active:tw-bg-primary/10">
      <SliderPrimitive.Range className="tw-absolute tw-h-full tw-bg-primary" />
    </SliderPrimitive.Track>
    <SliderPrimitive.Thumb className="tw-block tw-h-8 tw-w-8 tw-cursor-grab tw-rounded-full tw-border-[12px] tw-border-primary tw-bg-background tw-ring-offset-background tw-transition-colors  focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-primary focus-visible:tw-ring-offset-0 disabled:tw-pointer-events-none disabled:tw-opacity-50" />
  </SliderPrimitive.Root>
));
Slider.displayName = SliderPrimitive.Root.displayName;

export { Slider };
