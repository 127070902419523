import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

import { AppName, CountryCode } from "@krea/shared/types/common";
import { LoanApplicationReasonEnum } from "@krea/shared/types/loanApplication";
import { getCountryCode } from "@krea/shared/utils/countryUtils";

import { useCustomerFeaturesSettingsStore } from "../../app-settings/useCustomerFeaturesSettingsStore";

export interface IPreliminaryLoanAmountData {
  estimatedMaxLoanAmount: number;
  probabilityLoanApproved: string;
  useEdgeCaseLogic: boolean;
  estimatedMinLoanAmount: number;
  conversionValue: number | null;
}

export interface IEligibilityCheckData {
  estimatedMaxLoanAmount: number;
  probabilityLoanApproved: string;
  useEdgeCaseLogic: boolean;
  estimatedMinLoanAmount: number;
  conversionValue: number | null;
}

interface ILoanApplicationFormState {
  // Common
  formErrors: Record<string, string | number>;

  // form step
  currentStep: string;
  currentSteps: string[];

  // Contact Person
  email: string;
  mobilePhoneNumber: string;

  // Customer Company
  organisation: {
    name: string;
    organisationNumber: string;
  };

  // Privacy policy consent
  consentCheckbox: boolean;

  // Loan Application
  reason: string;
  requestedLoanAmount: number;

  // Real estate
  realEstateReason: string;
  propertyUnitDesignation: string;
  realEstateRequestedLoanAmount: number;

  // Factoring
  unpaidInvoices: {
    estimatedInvoiceAmount?: number;
    numberOfInvoices?: number;
    averagePaymentTimeDays: number;
  };
  unpaidInvoicesDescription: string;

  // Leasing
  leasing: {
    leasingAmount?: number;
    leasingObjectType?: string;
  };

  // Customer-Web
  preliminaryBid?: IPreliminaryLoanAmountData;

  eligibilityCheck?: IEligibilityCheckData;

  preApplicationLead?: {
    id: string;
  };

  // Partner-Web
  ssn: string;
  utmCampaign: string;

  remoteIdentification: {
    type?: string | null;
    URL?: string | null;
    URLExpiryDate?: string | null;
    isCustomerApplicationFlowAllowed: boolean;
  };

  notificationReceiver: string | null;

  utmData: Record<string, string> | null;
}

export interface ILoanApplicationFormStore extends ILoanApplicationFormState {
  // setters

  setCurrentStep: (currentStep: string) => void;
  setEmail: (email: string) => void;
  setMobilePhoneNumber: (mobilePhoneNumber: string) => void;
  setOrganisation: (
    organisation: ILoanApplicationFormState["organisation"],
  ) => void;
  setConsentCheckbox: (consentCheckbox: boolean) => void;
  setReason: (reason: LoanApplicationReasonEnum) => void;
  setRequestedLoanAmount: (requestedLoanAmount: number) => void;
  setUnpaidInvoices: (
    unpaidInvoices: ILoanApplicationFormState["unpaidInvoices"],
  ) => void;
  setUnpaidInvoicesDescription: (unpaidInvoicesDescription: string) => void;
  setPropertyUnitDesignation: (propertyUnitDesignation: string) => void;
  setRealEstateReason: (realEstateReason: LoanApplicationReasonEnum) => void;
  setRealEstateRequestedLoanAmount: (
    realEstateRequestedLoanAmount: number,
  ) => void;
  setLeasing: (leasing: ILoanApplicationFormState["leasing"]) => void;
  setPreliminaryBid: (
    preliminaryBid: ILoanApplicationFormState["preliminaryBid"],
  ) => void;
  setEligibilityCheck: (
    eligibilityCheck: ILoanApplicationFormState["eligibilityCheck"],
  ) => void;
  setPreApplicationLead: (
    preApplicationLead: ILoanApplicationFormState["preApplicationLead"],
  ) => void;
  setSsn: (ssn: string) => void;
  setUtmCampaign: (utmCampaign: string) => void;
  setNotificationReceiver: (notificationReceiver: string) => void;
  setRemoteIdentification: (
    remoteIdentification: Partial<
      ILoanApplicationFormState["remoteIdentification"]
    >,
  ) => void;

  setFormErrors: (error: ILoanApplicationFormState["formErrors"]) => void;

  clearSsn: () => void;
  clearNotificationReceiver: () => void;
  clearFormErrors: () => void;
  clearPreliminaryBid: () => void;
  clearRemoteIdentification: () => void;
  clearProductSpecificData: () => void;

  mergeFormState: (
    partialFormState: Partial<ILoanApplicationFormState>,
  ) => void;
  removeFormError: (key: string) => void;
  resetFormState: () => void;
}

const getInitialState = (
  countryCode: CountryCode,
  appName: AppName = import.meta.env.REACT_APP_NAME,
): ILoanApplicationFormState => {
  return {
    // Common
    formErrors: {},

    // form step
    currentStep: "",
    currentSteps: [],

    // Contact Person
    email: "",
    mobilePhoneNumber: "",

    // Customer Company
    organisation: {
      name: "",
      organisationNumber: "",
    },

    // Privacy policy consent
    consentCheckbox: appName === AppName.PARTNER || appName === AppName.ADMIN,

    // Loan Application
    reason: "" as LoanApplicationReasonEnum, // TODO: Remove type assertion when we fully migrate to TypeScript
    requestedLoanAmount: countryCode === CountryCode.FI ? 60_000 : 600_000,

    // Real estate
    realEstateReason: "", // TODO: Remove type assertion when we fully migrate to TypeScript
    propertyUnitDesignation: "",
    realEstateRequestedLoanAmount:
      countryCode === CountryCode.FI ? 500_000 : 5_000_000,

    // Factoring
    unpaidInvoices: {
      estimatedInvoiceAmount: undefined,
      numberOfInvoices: undefined,
      averagePaymentTimeDays: 30,
    },
    unpaidInvoicesDescription: "",

    // Leasing
    leasing: {
      leasingAmount: undefined,
      leasingObjectType: undefined,
    },

    // Customer-Web
    preliminaryBid: undefined,

    preApplicationLead: undefined,

    // Partner-Web
    ssn: "",
    utmCampaign: "",

    remoteIdentification: {
      type: null,
      URL: null,
      URLExpiryDate: null,
      isCustomerApplicationFlowAllowed: true,
    },

    notificationReceiver: null,
    utmData: null,
  };
};

export const useLoanApplicationFormStore = create(
  persist<ILoanApplicationFormStore>(
    (set) => {
      const initialState = getInitialState(getCountryCode());

      return {
        ...initialState,

        // setters
        setFormErrors: (error) =>
          set((state) => ({
            ...state,
            formErrors: { ...state.formErrors, ...error },
          })),
        setCurrentStep: (currentStep) =>
          set((state) => {
            const updatedCurrentSteps = [...state.currentSteps];

            // find index of currentStep in currentSteps and remove all steps after it including currentStep
            const index = updatedCurrentSteps.findIndex(
              (step) => step === currentStep,
            );

            if (index !== -1) {
              updatedCurrentSteps.splice(index);
            }

            // Then we add the currentStep to the end of the array
            updatedCurrentSteps.push(currentStep);

            return {
              ...state,
              currentStep,
              currentSteps: [...updatedCurrentSteps],
            };
          }),
        setEmail: (email) => set((state) => ({ ...state, email })),
        setMobilePhoneNumber: (mobilePhoneNumber) =>
          set((state) => ({ ...state, mobilePhoneNumber })),
        setOrganisation: (organisation) =>
          set((state) => ({ ...state, organisation })),
        setConsentCheckbox: (consentCheckbox) => {
          set((state) => ({ ...state, consentCheckbox }));
        },
        setReason: (reason) =>
          set((state) => ({
            ...state,
            // TODO: Remove type assertion when we fully migrate to TypeScript
            reason: reason,
          })),
        setRequestedLoanAmount: (requestedLoanAmount) =>
          set((state) => ({ ...state, requestedLoanAmount })),
        setUnpaidInvoices: (unpaidInvoices) =>
          set((state) => ({ ...state, unpaidInvoices })),
        setUnpaidInvoicesDescription: (unpaidInvoicesDescription) =>
          set((state) => ({ ...state, unpaidInvoicesDescription })),
        setPropertyUnitDesignation: (propertyUnitDesignation) =>
          set((state) => ({ ...state, propertyUnitDesignation })),
        setRealEstateReason: (realEstateReason) =>
          set((state) => ({
            ...state,
            // TODO: Remove type assertion when we fully migrate to TypeScript
            realEstateReason: realEstateReason,
          })),
        setRealEstateRequestedLoanAmount: (realEstateRequestedLoanAmount) =>
          set((state) => ({ ...state, realEstateRequestedLoanAmount })),
        setLeasing: (leasing) => set((state) => ({ ...state, leasing })),
        setPreliminaryBid: (preliminaryBid) =>
          set((state) => ({ ...state, preliminaryBid })),
        setEligibilityCheck: (eligibilityCheck) =>
          set((state) => ({ ...state, eligibilityCheck })),
        setPreApplicationLead: (preApplicationLead) =>
          set((state) => ({ ...state, preApplicationLead })),
        setSsn: (ssn) => set((state) => ({ ...state, ssn })),
        setUtmCampaign: (utmCampaign) =>
          set((state) => ({ ...state, utmCampaign })),
        setNotificationReceiver: (notificationReceiver) =>
          set((state) => ({ ...state, notificationReceiver })),
        setRemoteIdentification: (remoteIdentification) => {
          set((state) => ({
            ...state,
            remoteIdentification: {
              ...state.remoteIdentification,
              ...remoteIdentification,
            },
          }));
        },
        // clear functions
        clearSsn: () => set((state) => ({ ...state, ssn: initialState.ssn })),
        clearNotificationReceiver: () =>
          set((state) => ({
            ...state,
            notificationReceiver: initialState.notificationReceiver,
          })),

        clearFormErrors: () =>
          set((state) => ({ ...state, formErrors: initialState.formErrors })),
        clearPreliminaryBid: () =>
          set((state) => ({
            ...state,
            preliminaryBid: initialState.preliminaryBid,
          })),
        clearEligibilityCheck: () =>
          set((state) => ({
            ...state,
            eligibilityCheck: initialState.eligibilityCheck,
          })),
        clearRemoteIdentification: () => {
          set((state) => ({
            ...state,
            remoteIdentification: initialState.remoteIdentification,
          }));
        },
        clearProductSpecificData: () => {
          const { unpaidInvoices, propertyUnitDesignation, leasing } =
            initialState;

          set((state) => ({
            ...state,
            unpaidInvoices,
            propertyUnitDesignation,
            leasing,
          }));
        },

        // custom functions
        mergeFormState: (partialFormState) =>
          set((state) => ({ ...state, ...partialFormState })),
        removeFormError: (key) =>
          set((state) => {
            const { [key]: _, ...errors } = state.formErrors; // eslint-disable-line

            return { ...state, formErrors: errors };
          }),

        // reset store
        resetFormState: () => {
          const appName = useCustomerFeaturesSettingsStore.getState().appName;
          const countryCode =
            useCustomerFeaturesSettingsStore.getState().countryCode;

          const newState = getInitialState(countryCode, appName);

          set(newState);
        },
      };
    },
    {
      name: "application-form",
      version: 1,
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);
