import { cva, VariantProps } from "class-variance-authority";
import SVG from "react-inlinesvg";

import PreloaderSVG from "@krea/customer-features/images/svg/preloader.svg";
import { twMerge } from "@krea/shared/design-system/tailwind-utils";

const preloaderVariants = cva("", {
  variants: {
    size: {
      sm: "tw-w-5",
      md: "tw-w-6",
      lg: "tw-w-12",
      xl: "tw-w-14",
      xxl: "tw-w-24",
    },
  },
  defaultVariants: {
    size: "md",
  },
});

interface IPreloaderProps extends VariantProps<typeof preloaderVariants> {
  className?: string;
}

const Preloader = ({ className, size = "md" }: IPreloaderProps) => {
  return (
    <div
      className={twMerge(
        "tw-flex tw-items-center tw-justify-center",
        className,
      )}
    >
      <div className={preloaderVariants({ size })}>
        <SVG className="tw-h-auto tw-w-full" src={PreloaderSVG} />
      </div>
    </div>
  );
};

export default Preloader;
