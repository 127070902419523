import { parsePhoneNumberFromString } from "libphonenumber-js";

import { CountryCode } from "@krea/shared/types/common";

export const FormatNumber = (value: number) => {
  const formattedValue = new Intl.NumberFormat("sv", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);

  return formattedValue ? formattedValue.replace(/\./g, " ") : formattedValue; // edge formats with dots instead of spaces
};

export const FormatPhoneNumber = (value: string) => {
  const cleaned = value.replace(/[^\d]/g, "");

  return cleaned;
};

export const FormatPhoneNumberWithSpaces = (
  phoneNumber: string,
  countryCode: CountryCode,
) => {
  return parsePhoneNumberFromString(phoneNumber, countryCode)?.format(
    "NATIONAL",
  );
};
