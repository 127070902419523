import dayjs from "dayjs";
import relativeTime from "dayjs/esm/plugin/relativeTime";
import "dayjs/locale/sv";
import { map } from "lodash";
import { getI18n } from "react-i18next";
import { stdnum } from "stdnum";

import { CountryCode } from "@krea/shared/types/common";

import ImportantEuLoanIcon from "../images/svg/important-info/eu-loan-flag.svg";
import ImportantGoodContractIcon from "../images/svg/important-info/good-contract.svg";
import ImportantNoAdditionsRequiredIcon from "../images/svg/important-info/no-additions-required.svg";
import ImportantNoFeeIcon from "../images/svg/important-info/no-fee.svg";
import ImportantNoGuarantorRequiredIcon from "../images/svg/important-info/no-guarantor-required.svg";
import ImportantReducedGuarantorCommitmentIcon from "../images/svg/important-info/reduced-guarantor-commitment.svg";
import {
  ILenderResponseBenefit,
  LenderResponseBenefitTypeEnum,
} from "../types/lenderResponse";

dayjs.extend(relativeTime as dayjs.PluginFunc);

const NOTIFICATION_RECEIVER = {
  CONTACT_PERSON: "CONTACT_PERSON",
  PARTNER: "PARTNER",
  CONTACT_PERSON_AND_PARTNER: "CONTACT_PERSON_AND_PARTNER",
};

const BANKID_FLOW_STATES = {
  PENDING: "pending",
  FAILED: "failed",
  COMPLETE: "complete",
};

const COMPANY_TYPE = {
  LIMITED_COMPANY: "LIMITED_COMPANY",
  PARTNERSHIP: "PARTNERSHIP",
  SOLE_TRADER: "SOLE_TRADER",
};

const CUSTOMER_RATES = {
  FIRST_MONTH_RATE: "firstMonthInterestRate",
  MONTHLY_INTEREST_RATE: "comparableMonthlyInterestRate",
  YEARLY_INTEREST_RATE: "comparableYearlyInterestRate",
};

const HINT_CODE_STATES = {
  ERROR: "error",
  USER_CANCEL: "userCancel",
  OUTSTANDING_TRANSACTION: "outstandingTransaction",
  USER_SIGN: "userSign",
  START_FAILED: "startFailed",
};

const IMPORTANT_INFO_TYPE = {
  NO_ADDITIONS_REQUIRED: "NO_ADDITIONS_REQUIRED",
  REDUCED_GUARANTOR_COMMITMENT: "REDUCED_GUARANTOR_COMMITMENT",
  NO_GUARANTOR_REQUIRED: "NO_GUARANTOR_REQUIRED",
  FREE_EARLY_PAYMENT: "FREE_EARLY_PAYMENT",
  NO_LEASING_DOWN_PAYMENT: "NO_LEASING_DOWN_PAYMENT",
  NO_LIMIT_FEE: "NO_LIMIT_FEE",
  NO_SETUP_FEE: "NO_SETUP_FEE",
  EU_LOAN: "EU_LOAN",
  NO_WITHDRAWAL_FEE: "NO_WITHDRAWAL_FEE",
  NO_RECOURSE: "NO_RECOURSE",
  NO_AMORTIZATION: "NO_AMORTIZATION",
};

const isUrlRegex =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;

const RESOURCE_TYPE = {
  GUARANTOR: "GUARANTOR",
  APPLYING_COMPANY: "APPLYING_COMPANY",
};

const minFinancingAmountPerCountry: Record<
  CountryCode,
  { numericValue: number; stringValue: string }
> = {
  FI: { numericValue: 500, stringValue: "500 €" },
  SE: { numericValue: 5000, stringValue: "5000 kr" },
};

const amountValuesList = (values: number[], ratio: number) => {
  return map(values, (n) => n / ratio);
};

const FormatNumber = (value: number | bigint | null | undefined) => {
  if (value)
    return new Intl.NumberFormat("sv", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(value);

  return "";
};

const numberFromFormat = (
  value: string | number | null | undefined,
): number | null => {
  // For input fields that are dealing with numbers,
  // the value from the onChange can be a string, formatted string, number, null, or undefined.
  // this converts the value to a number, or null if it's not a valid number

  // Handle null or undefined by returning null
  if (value === null || value === undefined) {
    return null;
  }

  // If the original value is a string, attempt to clean and convert it
  if (typeof value === "string") {
    // Remove all non-digit characters
    const cleanedValue = value.replace(/\D/g, "");

    // If the cleaned value is empty, return null
    if (cleanedValue === "") {
      return null;
    }

    // Convert the cleaned string to a number
    const numericValue = Number(cleanedValue);

    // If conversion results in NaN, return null
    return isNaN(numericValue) ? null : numericValue;
  }

  // If the value is already a number, return it as is
  if (typeof value === "number") {
    return value;
  }

  // Default case: return null if none of the above conditions are met
  return null;
};

const getBenefits = (
  benefits?: ILenderResponseBenefit[],
): ILenderResponseBenefit[] => {
  if (!benefits) return [];

  return benefits.map((benefit) => {
    const benefitToMap = {
      ...benefit,
    };

    switch (benefit.type) {
      case LenderResponseBenefitTypeEnum.NO_ADDITIONS_REQUIRED:
        benefitToMap.icon = ImportantNoAdditionsRequiredIcon;
        break;

      case LenderResponseBenefitTypeEnum.REDUCED_GUARANTOR_COMMITMENT:
        benefitToMap.icon = ImportantReducedGuarantorCommitmentIcon;
        break;

      case LenderResponseBenefitTypeEnum.NO_GUARANTOR_REQUIRED:
        benefitToMap.icon = ImportantNoGuarantorRequiredIcon;
        break;

      case LenderResponseBenefitTypeEnum.FREE_EARLY_PAYMENT:
        benefitToMap.icon = ImportantNoFeeIcon;
        break;

      case LenderResponseBenefitTypeEnum.NO_LEASING_DOWN_PAYMENT:
        benefitToMap.icon = ImportantNoFeeIcon;
        break;

      case LenderResponseBenefitTypeEnum.NO_WITHDRAWAL_FEE:
        benefitToMap.icon = ImportantNoFeeIcon;
        break;

      case LenderResponseBenefitTypeEnum.EU_LOAN:
        benefitToMap.icon = ImportantEuLoanIcon;
        break;

      case LenderResponseBenefitTypeEnum.NO_RECOURSE:
        benefitToMap.icon = ImportantGoodContractIcon;
        break;

      case LenderResponseBenefitTypeEnum.NO_AMORTIZATION:
        benefitToMap.icon = ImportantNoFeeIcon;
        break;

      default:
    }

    return benefitToMap;
  });
};

const isIOS = () => !!window.navigator.userAgent.match(/iPhone|iPod|iPad/i);

const isMobile = () =>
  window.navigator.userAgent.match(
    /Android|webOS|iPhone|iPod|iPad|Blackberry/i,
  );

const isValidEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(email);
};

interface ValidatorReturnType {
  formatted: string; // Formatted ("-" etc)
  compact: string; // Cleaned from any formatting ("-" etc)
  isValid: boolean;
}

const orgNumValidator = (
  value: string,
  countryCode: CountryCode,
): ValidatorReturnType => {
  switch (countryCode) {
    case CountryCode.SE:
      return {
        formatted: stdnum[countryCode].orgnr.format(value),
        compact: stdnum[countryCode].orgnr.compact(value),
        isValid: stdnum[countryCode].orgnr.validate(value).isValid,
      };

    case CountryCode.FI:
      return {
        formatted: stdnum[countryCode].ytunnus.format(value),
        compact: stdnum[countryCode].ytunnus.compact(value),
        isValid: stdnum[countryCode].ytunnus.validate(value).isValid,
      };
    default:
      throw new Error(`Country code [${countryCode}] not supported`);
  }
};

const convertTo12DigitSSN = (ssn: string) => {
  // Extract the year, month, and day from the SSN
  const [datePart, serialPart] = ssn.split("-"); // Example: 'YYMMDD' and 'XXXX'
  const year = parseInt(datePart.slice(0, 2)); // Extract the year (YY)
  const currentYear = new Date().getFullYear(); // Get current year, e.g., 2024
  const currentCentury = Math.floor(currentYear / 100); // E.g., 20

  // Deduce the full year (YYYY)
  let fullYear;

  // Current two-digit year to compare with
  const currentTwoDigitYear = currentYear % 100;

  if (year > currentTwoDigitYear) {
    // If year is greater than current two-digit year, assume it's from the previous century
    fullYear = (currentCentury - 1) * 100 + year; // 1900s
  } else {
    // Otherwise, assume it's from the current century
    fullYear = currentCentury * 100 + year; // 2000s
  }

  // Return the 12-digit SSN
  return `${fullYear}${datePart.slice(2)}-${serialPart}`;
};

const _SESsnValidator = (ssn: string): ValidatorReturnType => {
  const stdNumSESsn = stdnum[CountryCode.SE].personnummer;

  // always needs a formated SSN to validate
  const isValid =
    ssn.length > 0
      ? stdNumSESsn.validate(stdNumSESsn.format(ssn)).isValid
      : false;
  let formatted = ssn;

  if (isValid) {
    formatted = stdNumSESsn.format(ssn);

    // Currently the valid formatted always returns the 10 digit format (plus the dash)
    // But we always want to work with the 12 digit format
    if (formatted.length === 11) {
      formatted = convertTo12DigitSSN(formatted);
    }
  }
  const compact = formatted.replace(/-/g, "");

  return {
    formatted,
    compact,
    isValid,
  };
};

const ssnValidator = (
  value: string,
  countryCode: CountryCode,
): ValidatorReturnType => {
  switch (countryCode) {
    case CountryCode.SE:
      return _SESsnValidator(value);
    case CountryCode.FI:
      return {
        formatted: stdnum[countryCode].hetu.format(value),
        compact: stdnum[countryCode].hetu.compact(value),
        isValid: stdnum[countryCode].hetu.validate(value).isValid,
      };
    default:
      throw new Error(`Country code [${countryCode}] not supported`);
  }
};

const isValidUrl = (urlString: string) => {
  return !!urlString && urlString.match(isUrlRegex);
};

const NormalizeRequestedPaymentTimeMonths = (months: number) => {
  const { t } = getI18n();

  const numberOfYears = Math.floor(months / 12);
  const numberOfMonths = months % 12;

  const monthPostfix = `${numberOfMonths} ${numberOfMonths === 1 ? t("commons.month") : t("commons.month_plural")}`;

  const monthPostfixWithYear = `${numberOfMonths} ${
    numberOfMonths === 1 ? t("commons.months_short") : t("commons.months_short")
  }`;

  const yearPrefix = `${numberOfYears} ${t("commons.year", { count: numberOfYears })}`;

  if (numberOfYears && !numberOfMonths) {
    return yearPrefix;
  }

  if (numberOfYears && numberOfMonths) {
    return `${yearPrefix} ${t("commons.and")} ${monthPostfixWithYear}`;
  }

  return monthPostfix;
};

const ScrollToElement = (
  element: string,
  offset: null | undefined,
  callback: (() => void) | undefined,
) => {
  let offsetValue;

  if (offset != null) {
    offsetValue = offset;
  } else {
    offsetValue = -140;
  }

  import("react-scroll")
    .then((module) => {
      const scroller = module.scroller;
      const Events = module.Events;

      scroller.scrollTo(element, {
        duration: 1000,
        delay: 100,
        smooth: true,
        offset: offsetValue,
      });

      if (callback) {
        Events.scrollEvent.register("end", () => {
          callback();
          Events.scrollEvent.remove("end");
        });
      }
    })
    .catch((error: unknown) => {
      console.error("Unable to load react-scroll module");
    });
};

const ScrollToTop = () => {
  import("react-scroll")
    .then((module) => {
      const Scroll = module.default;
      const scroll = Scroll.animateScroll;

      scroll.scrollToTop({
        duration: 1000,
        delay: 0,
        smooth: true,
      });
    })
    .catch((error: unknown) => {
      console.error("Unable to load react-scroll module");
    });
};

const termsOfServiceLink = (
  country: string,
  websiteUrl: string,
  lang: string,
) => {
  const links_SE = (lang: string) => {
    switch (lang) {
      case "sv":
        return "terms-of-service";
      case "en":
        return "terms-of-service";
      default:
        return null;
    }
  };

  const links_FI = (lang: string) => {
    switch (lang) {
      case "fi":
        return "kayttoehdot";
      case "sv":
        return "kayttoehdot";
      case "en":
        return "kayttoehdot";
      default:
        return null;
    }
  };

  switch (country) {
    case "SE":
      return `${websiteUrl}/${links_SE(lang)}`;
    case "FI":
      return `${websiteUrl}/${links_FI(lang)}`;
    default:
      return `${websiteUrl}/terms-of-service`;
  }
};

const privacyPolicyLink = (
  country: string,
  websiteUrl: string,
  lang: string,
) => {
  const links_SE = (lang: string) => {
    switch (lang) {
      case "sv":
        return "integritetspolicy";
      case "en":
        return "privacy-policy";
      default:
        return null;
    }
  };

  const links_FI = (lang: string) => {
    switch (lang) {
      case "fi":
        return "tietosuojakaytanto";
      case "sv":
        return "tietosuojakaytanto";
      case "en":
        return "tietosuojakaytanto";
      default:
        return null;
    }
  };

  switch (country) {
    case "SE":
      return `${websiteUrl}/${links_SE(lang)}`;
    case "FI":
      return `${websiteUrl}/${links_FI(lang)}`;
    default:
      return `${websiteUrl}/privacy-policy`;
  }
};

export * from "./bids";
export * from "./country";
export * from "./fieldRequests";
export * from "./loanApplications";
export * from "./testConstants";

export {
  amountValuesList,
  BANKID_FLOW_STATES,
  COMPANY_TYPE,
  CUSTOMER_RATES,
  FormatNumber,
  getBenefits,
  HINT_CODE_STATES,
  IMPORTANT_INFO_TYPE,
  isIOS,
  isMobile,
  isUrlRegex,
  isValidEmail,
  isValidUrl,
  minFinancingAmountPerCountry,
  NormalizeRequestedPaymentTimeMonths,
  NOTIFICATION_RECEIVER,
  numberFromFormat,
  orgNumValidator,
  privacyPolicyLink,
  RESOURCE_TYPE,
  ScrollToElement,
  ScrollToTop,
  ssnValidator,
  termsOfServiceLink,
};
