import { useTranslation } from "react-i18next";
import SVG from "react-inlinesvg";
import GoogleSVG from "@krea/customer-features/images/svg/google/google-logo.svg";
import GoogleStarsSVG from "@krea/customer-features/images/svg/google/google-stars.svg";
import { Text } from "@krea/customer-features/shared-components/text";
import { getCountryCode } from "@krea/customer-features/utils";
import { twMerge } from "@krea/shared/design-system/tailwind-utils";
import { CountryCode } from "@krea/shared/types/common";
import { useGetReviewProviderScores } from "@krea/customer-features/store/cms-site-settings/hooks";
import { ReviewProviderEnum } from "@krea/customer-features/types/reviewProvider";

const countryCode = getCountryCode();

interface IContactsGoogleProps {
  className?: string;
  isSimpleVersion?: boolean;
}

const ContactsGoogle = ({
  className,
  isSimpleVersion,
}: IContactsGoogleProps) => {
  const { t } = useTranslation();

  const data = useGetReviewProviderScores(ReviewProviderEnum.GOOGLE_REVIEWS);
  const score = `${data?.score} / ${data?.maxScore}`;
  const numberOfReviews = `${data?.numberOfReviews}`;

  const fixedLinkToReviews = (() => {
    switch (countryCode) {
      case CountryCode.SE:
        return "https://www.google.com/search?q=krea&rlz=1C5CHFA_enSE1079SE1079&oq=krea++&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIHCAEQABiABDIHCAIQABiABDIHCAMQABiABDIGCAQQRRg8MgYIBRBFGDwyBggGEEUYPDIGCAcQRRg80gEIMTQwOWowajSoAgCwAgA&sourceid=chrome&ie=UTF-8#lrd=0x465f9da2eaf1bd2b:0xdeb87fa3293950cd,1,,,,";

      case CountryCode.FI:
        return "https://www.google.fi/search?q=kredita+oy&sca_esv=559344913&source=hp&ei=KcjlZN-OMIOSxc8Pw7iOkA8&iflsig=AD69kcEAAAAAZOXWOaf18IlCAADblheTMlWq3Ugx6zzE&ved=0ahUKEwifx_6Ls_KAAxUDSfEDHUOcA_IQ4dUDCAk&uact=5&oq=kredita+oy&gs_lp=Egdnd3Mtd2l6IgprcmVkaXRhIG95MgcQABgTGIAESL4MUABYlwhwAHgAkAEAmAFKoAGqBKoBAjEwuAEDyAEA-AEBwgIREC4YgAQYsQMYgwEYxwEYrwHCAgsQABiKBRixAxiDAcICCxAAGIAEGLEDGIMBwgILEC4YgAQYxwEY0QPCAgsQLhiABBixAxiDAcICCBAAGIAEGLEDwgIFEAAYgATCAggQLhiABBixA8ICCxAuGIMBGLEDGIAEwgIOEC4YgAQYsQMYxwEYrwHCAgcQABiABBgKwgIJEAAYExiABBgKwgIGEAAYHhgTwgIIEAAYHhgTGArCAgcQABgNGIAEwgINEC4YDRiABBjHARivAcICCRAAGA0YgAQYCg&sclient=gws-wiz#lrd=0xaf3b5115781e147b:0x9603c740b26dc66a,1,,";

      default:
        return "";
    }
  })();

  const linkToReviews = data?.linkToProviderReviews
    ? data.linkToProviderReviews
    : fixedLinkToReviews;

  if (!data) {
    return null;
  }

  return (
    <a
      href={linkToReviews}
      target="_blank"
      style={{ textDecoration: "none", color: "inherit" }}
      rel="noreferrer"
    >
      <div className={twMerge("tw-flex tw-items-center", className)}>
        <div style={{ width: "115px" }}>
          <SVG
            src={GoogleStarsSVG}
            className="tw-mt-0 tw-h-auto tw-w-full tw-p-0"
          />
        </div>
        <Text
          size="sm"
          className="tw-mx-2 tw-shrink-0 tw-text-nowrap tw-font-bold"
        >
          {score}
        </Text>
        <div
          className={twMerge([
            "tw-relative tw-w-6",
            "before:tw-block before:tw-content-[''] before:tw-pb-[100%]",
          ])}
        >
          <SVG
            src={GoogleSVG}
            className="tw-absolute tw-left-0 tw-top-0 tw-h-auto tw-w-full"
          />
        </div>
        <Text
          size="sm"
          className="tw-ml-1 tw-shrink-0 tw-text-nowrap tw-font-bold"
        >
          Google
        </Text>
        {!isSimpleVersion && (
          <>
            <Text
              size="sm"
              className="tw-mx-2 tw-font-bold"
              style={{ opacity: "0.6" }}
            >
              |
            </Text>
            <Text
              size="sm"
              className="tw-shrink-0 tw-text-nowrap tw-font-bold"
              style={{ opacity: "0.6" }}
            >
              {t("reviewProviderScores.google.numberOfReviews", {
                numberOfReviews: numberOfReviews,
              })}
            </Text>
          </>
        )}
      </div>
    </a>
  );
};

export default ContactsGoogle;
