import { useEffect, useRef, useState } from "react";

const isMobileCheck = () => {
  if (window.matchMedia("(max-width: 1200px)").matches) {
    return true;
  } else {
    return false;
  }
};

export function useMobile() {
  const [isMobile, setIsMobile] = useState(isMobileCheck());

  useEffect(() => {
    function windowResize() {
      if (isMobileCheck()) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }

    windowResize();

    window.addEventListener("resize", windowResize);

    return () => {
      window.removeEventListener("resize", windowResize);
    };
  }, []);

  return isMobile;
}

export function usePrevious(value: Record<string, string | number>) {
  const ref = useRef<Record<string, string | number> | undefined>(undefined);

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}

export * from "./useDelayedAction";
export * from "./useFormatCurrency";
export * from "./useGetCurrentLoanApplicationID";
