import { isEmpty } from "lodash";
import { Trans, useTranslation } from "react-i18next";
import { useShallow } from "zustand/react/shallow";

import { useFormatCurrency, useMobile } from "@krea/customer-features/hooks";

import { AccordionItem } from "@krea/customer-features/shared-components/accordion/AccordionItem";
import Button from "@krea/customer-features/shared-components/button";
import { ResultContent } from "@krea/customer-features/shared-components/preliminary-bid-result/ResultContent";
import { Text } from "@krea/customer-features/shared-components/text";
import { useLoanApplicationFormStore } from "@krea/customer-features/store/loan-application-form/hooks";
import { CountryCode } from "@krea/shared/types/common";
import { Check, ExternalLink } from "lucide-react";
import BidItemLabel from "@krea/customer-features/shared-components/bid-dashboards/commons/BidItemLabel";

import {
  googleCalendarUrlSE,
  googleCalendarUrlFI,
} from "@krea/shared/constants/index";
import ContactsTrustpilot from "@krea/customer-features/shared-components/app/contacts/ContactsTrustpilot";
import ContactsGoogle from "@krea/customer-features/shared-components/app/contacts/ContactsGoogle";
import { ReactNode } from "react";
import { getCompanyBrandName } from "@krea/shared/utils";
import { useCustomerFeaturesSettingsStore } from "@krea/customer-features/store/app-settings/useCustomerFeaturesSettingsStore";

const CALENDAR_URL_MAP: Record<CountryCode, string> = {
  [CountryCode.SE]: `${googleCalendarUrlSE}##follow`,
  [CountryCode.FI]: `${googleCalendarUrlFI}##follow`,
};

const StyledCheckmarkWithText = ({ text }: { text: string }) => (
  <div className="tw-flex tw-items-center tw-space-x-3 ">
    <div className="tw-flex tw-h-[20px] tw-w-[20px] tw-shrink-0 tw-items-center tw-bg-primary-150 tw-justify-center tw-rounded-full tw-border tw-border-solid tw-border-primary">
      <Check
        strokeWidth={5}
        className="tw-size-3 tw-m-0 tw-p-0 tw-text-primary"
      />
    </div>
    <Text size="sm" className=" tw-m-0 tw-p-0">
      {text}
    </Text>
  </div>
);

const StartApplicationSection = ({
  handleSubmit,
}: {
  handleSubmit: () => void;
}) => {
  const { t } = useTranslation();
  const isMobile = useMobile();
  const countryCode = useCustomerFeaturesSettingsStore(
    (state) => state.countryCode,
  );
  const contactSectionPerCountry: Record<CountryCode, ReactNode> = {
    [CountryCode.FI]: <ContactsGoogle className="" isSimpleVersion />,
    [CountryCode.SE]: (
      <div>
        <ContactsTrustpilot className="" isSimpleVersion />
        <ContactsGoogle className="tw-mt-2" isSimpleVersion />
      </div>
    ),
  };

  return (
    <>
      <div className="tw-rounded-xl tw-border tw-border-solid tw-bg-white tw-border-primary">
        <div className="tw-rounded-t-xl tw-bg-primary-150 tw-px-5 tw-py-4">
          <Text size="lg" className="tw-font-bold">
            {t("amountEstimatorWidget.startApplication", {
              company: getCompanyBrandName(countryCode),
            })}
          </Text>
        </div>

        <div className="tw-ml-5 tw-mt-5 tw-text-gray-700">
          {contactSectionPerCountry[countryCode]}{" "}
        </div>

        <div className="tw-px-5 tw-py-4">
          {isMobile ? (
            <AccordionItem
              titleStyling="tw-font-normal tw-text-md"
              title={t("amountEstimatorWidget.seeHowItWorks")}
            >
              <div className="tw-space-y-2">
                <StyledCheckmarkWithText
                  text={t("amountEstimatorWidget.howItWorksDescription1")}
                />
                <StyledCheckmarkWithText
                  text={t("amountEstimatorWidget.howItWorksDescription2")}
                />
                <StyledCheckmarkWithText
                  text={t("amountEstimatorWidget.howItWorksDescription3")}
                />
              </div>
            </AccordionItem>
          ) : (
            <div className="tw-space-y-2 tw-py-2">
              <StyledCheckmarkWithText
                text={t("amountEstimatorWidget.howItWorksDescription1")}
              />
              <StyledCheckmarkWithText
                text={t("amountEstimatorWidget.howItWorksDescription2")}
              />
              <StyledCheckmarkWithText
                text={t("amountEstimatorWidget.howItWorksDescription3")}
              />
            </div>
          )}
        </div>

        <div className="tw-mx-5 tw-mb-4">
          <Button
            size="lg"
            endIcon={<ExternalLink className="!tw-fill-none" />}
            className="tw-w-full tw-bg-cta hover:tw-bg-cta/90"
            onClick={handleSubmit}
          >
            {t("amountEstimatorWidget.continueToApplication")}
          </Button>
        </div>
      </div>
    </>
  );
};

const foretagarnaMemberCompensation = (amount: number) => {
  if (amount < 100_000) {
    return 1000;
  } else if (amount < 200_000) {
    return 1500;
  } else if (amount < 300_000) {
    return 2500;
  } else if (amount < 400_000) {
    return 3000;
  } else if (amount < 500_000) {
    return 4000;
  } else if (amount < 600_000) {
    return 5000;
  } else if (amount < 700_000) {
    return 6000;
  } else if (amount < 800_000) {
    return 7000;
  } else if (amount < 900_000) {
    return 8000;
  } else if (amount < 1_000_000) {
    return 9000;
  } else if (amount < 1_300_000) {
    return 10_000;
  } else if (amount < 1_700_000) {
    return 11_000;
  } else if (amount < 1_800_000) {
    return 12_000;
  } else if (amount < 1_900_000) {
    return 13_000;
  } else if (amount < 2_000_000) {
    return 14_000;
  } else if (amount < 3_000_000) {
    return 15_000;
  } else if (amount < 4_000_000) {
    return 20_000;
  } else if (amount < 10_000_000) {
    return 25_000;
  } else if (amount < 20_000_000) {
    return 30_000;
  } else if (amount < 50_000_000) {
    return 40_000;
  } else {
    // All 50M or above
    return 50_000;
  }
};
interface IAmountEstimatorResultProps {
  onGoBack: () => void;
  onContinueToApply: () => void;
  utmSourceIdentifier?: string;
}

export const AmountEstimatorResult = ({
  onGoBack,
  onContinueToApply,
  utmSourceIdentifier,
}: IAmountEstimatorResultProps) => {
  // This is a conditional rendering for specific "Företagarna" based on the utmSourceIdentifier.
  // If they use our widget, we show them a special extra content.
  const showForetagarnaContent =
    utmSourceIdentifier === "foretagarna_sverige_service_ab";

  const { t } = useTranslation();
  const { countryCode, currency } = useCustomerFeaturesSettingsStore(
    (state) => ({
      countryCode: state.countryCode,
      currency: state.currency,
    }),
  );
  const [formattedCurrencyValue] = useFormatCurrency({
    currency,
  });

  const goBack = () => {
    onGoBack();
  };

  const handleSubmit = () => {
    onContinueToApply();
  };

  const { organisationName, preliminaryBid, requestedLoanAmount } =
    useLoanApplicationFormStore(
      useShallow((state) => ({
        organisationName: state.organisation.name,
        preliminaryBid: state.preliminaryBid,
        requestedLoanAmount: state.requestedLoanAmount,
      })),
    );

  const probabilityOptions = {
    low: t("amountEstimatorWidget.probabilityOptions.low"),
    medium: t("amountEstimatorWidget.probabilityOptions.medium"),
    high: t("amountEstimatorWidget.probabilityOptions.high"),
  };

  const estimatedLowerAmount = preliminaryBid?.estimatedMinLoanAmount;

  const estimatedAmount = preliminaryBid?.estimatedMaxLoanAmount;

  const isEdgeCase = preliminaryBid?.useEdgeCaseLogic;

  const maxAccuracyAmount = countryCode === CountryCode.SE ? 3000000 : 300000;

  const probabilityKey =
    preliminaryBid &&
    (preliminaryBid.probabilityLoanApproved as keyof typeof probabilityOptions);

  const probability = probabilityKey
    ? probabilityOptions[probabilityKey]
    : null;

  const reasonForLowProbabilityTextMap: Record<CountryCode, string> = {
    [CountryCode.SE]: t(
      "amountEstimatorWidget.reasonForLowProbabilityDescription2Swedish",
    ),
    [CountryCode.FI]: t(
      "amountEstimatorWidget.reasonForLowProbabilityDescription2Finnish",
    ),
  };

  if (isEmpty(preliminaryBid)) {
    return null;
  }

  return (
    <div>
      <ResultContent
        headingContent={
          <>
            <Text size="sm" className="tw-font-normal">
              {organisationName}
            </Text>

            {isEdgeCase ? (
              <Text size="lg" className="tw-font-bold">
                {t("amountEstimatorWidget.weCouldNotEstimateYourCreditLimit")}
              </Text>
            ) : (
              <>
                {(probability === probabilityOptions.high ||
                  probability === probabilityOptions.medium) && (
                  <Text size="lg" className="tw-font-bold">
                    {estimatedAmount &&
                      estimatedLowerAmount !== undefined &&
                      formattedCurrencyValue(estimatedLowerAmount)}
                    {" - "}
                    {estimatedAmount && formattedCurrencyValue(estimatedAmount)}
                  </Text>
                )}
                {probability === probabilityOptions.low && (
                  <Text size="lg" className="tw-font-bold">
                    {t("amountEstimatorWidget.lowLikelihood")}
                  </Text>
                )}
              </>
            )}
          </>
        }
        backButton={{
          onBack: goBack,
          buttonText: t("amountEstimatorWidget.makeAnotherEstimate"),
        }}
        probability={probability}
        probabilityOptions={probabilityOptions}
        isEdgeCase={isEdgeCase}
      >
        <>
          {showForetagarnaContent && estimatedAmount && (
            <>
              <div>
                <div className="tw-flex tw-mb-[-28px]">
                  <BidItemLabel
                    text={t("foretagarnaContent.title")}
                    accentColor={"primary"}
                  />
                </div>
              </div>

              <Text
                size="lg"
                className=" tw-font-normal tw-border tw-border-solid tw-border-primary tw-px-5 tw-pb-3 tw-pt-8 tw-rounded tw-mb-5"
              >
                <Trans
                  i18nKey="foretagarnaContent.resultMemberBenefitText"
                  values={{
                    compensation: formattedCurrencyValue(
                      foretagarnaMemberCompensation(
                        isEdgeCase ? requestedLoanAmount : estimatedAmount,
                      ),
                    ),
                    amount: formattedCurrencyValue(
                      isEdgeCase ? requestedLoanAmount : estimatedAmount,
                    ),
                  }}
                  components={{
                    compensation: <span className="tw-font-bold" />,
                    amount: <span className="tw-font-bold" />,
                  }}
                />
              </Text>
            </>
          )}
          {isEdgeCase ? (
            <>
              <div className="tw-mb-5 tw-rounded tw-border tw-border-solid tw-px-5 tw-py-4">
                <Text size="md" className="tw-font-normal">
                  {t("amountEstimatorWidget.ourEstimateIsAccurateUpTo")}{" "}
                  {formattedCurrencyValue(maxAccuracyAmount)}
                </Text>
              </div>

              <div className="2xl:tw-flex tw-mb-5 tw-items-center tw-rounded tw-border tw-border-solid tw-px-5 tw-py-4 ">
                <Text size="md" className="tw-font-normal">
                  {t("amountEstimatorWidget.doYouWantAnEstimateFromAnAdvisor")}
                </Text>

                <a
                  href={CALENDAR_URL_MAP[countryCode]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button variant="text" size="sm" className="tw-font-bold">
                    {t("amountEstimatorWidget.bookAMeeting")}
                  </Button>
                </a>
              </div>
            </>
          ) : (
            <>
              {probability === probabilityOptions.high && (
                <div className="tw-pb-4">
                  <div className="tw-mb-5 tw-rounded tw-border tw-border-solid tw-px-5 tw-py-4">
                    <Text size="md" className="tw-font-normal">
                      {t("amountEstimatorWidget.largerAmountsPossible")}
                    </Text>
                  </div>

                  <AccordionItem
                    titleStyling="tw-font-normal tw-text-md"
                    title={t("amountEstimatorWidget.seeHowWeEstimated")}
                  >
                    <Text size="sm">
                      {t("amountEstimatorWidget.howWeEstimatedDescription1")}{" "}
                      <br />
                      <br />
                      {t("amountEstimatorWidget.howWeEstimatedDescription2")}
                      <br />
                      <br />
                      {t("amountEstimatorWidget.howWeEstimatedDescription3")}
                    </Text>
                  </AccordionItem>
                </div>
              )}
              {probability === probabilityOptions.medium && (
                <div className="tw-pb-4">
                  <AccordionItem
                    titleStyling="tw-font-normal tw-text-md"
                    className="tw-mb-5"
                    title={t(
                      "amountEstimatorWidget.howCanYouImproveYourChances",
                    )}
                  >
                    <Text size="sm">
                      {t(
                        "amountEstimatorWidget.improveYourChancesDescription1",
                      )}
                      <br />
                      <br />
                      {t(
                        "amountEstimatorWidget.improveYourChancesDescription2",
                      )}
                      <br />
                      <br />
                      <ul>
                        <li>
                          {t(
                            "amountEstimatorWidget.improveYourChancesDescription3",
                          )}
                        </li>
                        <li>
                          {t(
                            "amountEstimatorWidget.improveYourChancesDescription4",
                          )}
                        </li>
                        <li>
                          {t(
                            "amountEstimatorWidget.improveYourChancesDescription5",
                          )}
                        </li>
                      </ul>
                      {t(
                        "amountEstimatorWidget.improveYourChancesDescription6",
                      )}
                      <br />
                      <br />
                      {t(
                        "amountEstimatorWidget.improveYourChancesDescription7",
                      )}
                    </Text>
                  </AccordionItem>

                  <AccordionItem
                    titleStyling="tw-font-normal tw-text-md"
                    title={t("amountEstimatorWidget.seeHowWeEstimated")}
                  >
                    <Text size="sm">
                      {t("amountEstimatorWidget.howWeEstimatedDescription1")}{" "}
                      <br />
                      <br />
                      {t("amountEstimatorWidget.howWeEstimatedDescription2")}
                      <br />
                      <br />
                      {t("amountEstimatorWidget.howWeEstimatedDescription3")}
                    </Text>
                  </AccordionItem>
                </div>
              )}
              {probability === probabilityOptions.low && (
                <div>
                  <AccordionItem
                    titleStyling="tw-font-normal tw-text-md"
                    className="tw-mb-4"
                    title={t("amountEstimatorWidget.why")}
                  >
                    <Text size="sm">
                      {t(
                        "amountEstimatorWidget.reasonForLowProbabilityDescription1",
                      )}
                      <br /> <br />
                      <ul>
                        <li>{reasonForLowProbabilityTextMap[countryCode]}</li>
                        <li>
                          {t(
                            "amountEstimatorWidget.reasonForLowProbabilityDescription3",
                          )}
                        </li>
                        <li>
                          {t(
                            "amountEstimatorWidget.reasonForLowProbabilityDescription4",
                          )}
                        </li>
                      </ul>
                      {t(
                        "amountEstimatorWidget.reasonForLowProbabilityDescription5",
                      )}
                      <br /> <br />
                      {t(
                        "amountEstimatorWidget.reasonForLowProbabilityDescription6",
                      )}
                    </Text>
                  </AccordionItem>
                </div>
              )}
            </>
          )}
        </>
      </ResultContent>

      <StartApplicationSection handleSubmit={handleSubmit} />
    </div>
  );
};
