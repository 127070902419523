import React, { HTMLAttributes } from "react";

import { twMerge } from "@krea/shared/design-system/tailwind-utils";

import styles from "./Text.module.scss";

export interface ITextProps extends HTMLAttributes<HTMLElement> {
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "xxl";
  htmlTag?:
    | "div"
    | "span"
    | "p"
    | "section"
    | "dl"
    | "dt"
    | "dd"
    | "label"
    | "li";
  className?: string;
  children?: React.ReactNode;
  htmlFor?: string;
}

export const Text = ({
  size = "md",
  children,
  className,
  htmlTag = "div",
  htmlFor,
  ...rest
}: ITextProps) => {
  return React.createElement(
    htmlTag,
    { className: twMerge(styles.root, className, styles[size]), ...rest },
    children,
  );
};
