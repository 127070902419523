import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React, { useEffect } from "react";
import * as Yup from "yup";
import { I18nextProvider } from "react-i18next";

import { TWStylesWrapper } from "@krea/shared/design-system/TWStylesWrapper";
import "./index.css";
import i18n from "./i18n";

import { hexToRgb, lightenHexToRgb } from "./utils";
import {
  AmountEstimatorWidget,
  AmountEstimatorWidgetSchema,
} from "./widgets/amount-estimator";
import { CountryCode } from "@krea/shared/types/common";

const WidgetPropsSchema = Yup.object({
  // It can be an empty string, but it has to exist. Empty string is what we always should use if we use the widget ourselves internally.
  utmSourceIdentifier: Yup.string()
    .strict() // Ensures it remains a string, no coercion
    .defined(
      "UTM Source Identifier is required, you have to get this from Krea",
    ), // Ensures the field exists.
  utmCampaign: Yup.string().optional(),
  countryCode: Yup.mixed<CountryCode>()
    .oneOf([CountryCode.SE])
    .required(`Country code is required, supported values: ${CountryCode.SE}`),
  language: Yup.string<"sv">()
    .oneOf(["sv"])
    .required(`Language is required, supported values: sv`),
  uiSettings: Yup.object({
    primaryColor: Yup.string().optional(),
    primaryColorLight: Yup.string().optional(),
    accentColor: Yup.string().optional(),
    accentColorLight: Yup.string().optional(),
    ctaColor: Yup.string().optional(),
  }).optional(),
  amountEstimatorWidget: AmountEstimatorWidgetSchema,
});

export type WidgetProps = Yup.InferType<typeof WidgetPropsSchema>;

export const Widget = (props: WidgetProps) => {
  useEffect(() => {
    // validate props
    if (!("amountEstimatorWidget" in props)) {
      throw new Error(
        "amountEstimatorWidget is required. It can be an empty object, but its required",
      );
    }
    WidgetPropsSchema.validateSync(props, {
      disableStackTrace: true,
    });

    const {
      primaryColor,
      primaryColorLight,
      accentColor,
      accentColorLight,
      ctaColor,
    } = props?.uiSettings ?? {};

    // == Handle dynamic colors ==
    if (primaryColor) {
      const rgbColor = hexToRgb(primaryColor);
      document.documentElement.style.setProperty(
        "--ka-widget-color-primary",
        rgbColor,
      );
      if (!primaryColorLight) {
        document.documentElement.style.setProperty(
          "--ka-widget-color-primary-light",
          lightenHexToRgb(primaryColor, 50),
        );
      }
    }

    if (primaryColorLight) {
      document.documentElement.style.setProperty(
        "--ka-widget-color-primary-light",
        hexToRgb(primaryColorLight),
      );
    }

    if (accentColor) {
      document.documentElement.style.setProperty(
        "--ka-widget-color-accent",
        hexToRgb(accentColor),
      );
      if (!accentColorLight) {
        document.documentElement.style.setProperty(
          "--ka-widget-color-accent-light",
          lightenHexToRgb(accentColor, 50),
        );
      }
    }
    if (accentColorLight) {
      document.documentElement.style.setProperty(
        "--ka-widget-color-accent-light",
        hexToRgb(accentColorLight),
      );
    }

    if (ctaColor) {
      document.documentElement.style.setProperty(
        "--ka-widget-color-cta",
        hexToRgb(ctaColor),
      );
    }
  }, [props]);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: !import.meta.env.DEV,
      },
    },
  });

  return (
    <React.StrictMode>
      <I18nextProvider i18n={i18n}>
        <TWStylesWrapper>
          {/* 
            Important className (ka3r)!! 
            This is scoping styles, so all tailwind classes are scoped under this className 
          */}
          {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
          <div className="ka3r">
            <QueryClientProvider client={queryClient}>
              <AmountEstimatorWidget
                {...props.amountEstimatorWidget}
                utmSourceIdentifier={props.utmSourceIdentifier}
                utmCampaign={props.utmCampaign}
              />
            </QueryClientProvider>
          </div>
        </TWStylesWrapper>
      </I18nextProvider>
    </React.StrictMode>
  );
};
