import { cva, VariantProps } from "class-variance-authority";
import { Check } from "lucide-react";
import React, {
  ComponentPropsWithRef,
  forwardRef,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";

import twMerge from "@krea/customer-features/utils/tailwind-utils";

import { Helper } from "../../helper";
import { Text } from "../../text";
import { Error } from "../error";
import { Label } from "../label";

interface IAdditionalTextProps {
  className: string;
  children: ReactNode;
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "xxl";
}

const AdditionalText: React.FC<IAdditionalTextProps> = ({
  className,
  children,
  size,
}) => {
  return (
    <Text
      size={size}
      className={twMerge(
        // styles.additionalText,
        "tw-select-none",
        "tw-text-gray-dark tw-flex tw-shrink-0 tw-items-center tw-justify-center tw-whitespace-nowrap tw-font-bold",
        className,
      )}
    >
      {children}
    </Text>
  );
};

const useCombinedRefs = (...refs: React.Ref<HTMLInputElement>[]) => {
  const targetRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return;

      if (typeof ref === "function") {
        ref(targetRef.current);
      } else {
        (ref as any).current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
};

const inputVariants = cva("", {
  variants: {
    size: {
      sm: ["tw-h-9", "tw-text-xs"],
      md: ["tw-h-10", "tw-text-sm"],
      lg: ["tw-h-12", "tw-text-base"],
      xl: ["tw-h-16", "tw-text-lg"],
    },
  },
  defaultVariants: {
    size: "md",
  },
});

const textVariants = cva("", {
  variants: {
    size: {
      sm: ["tw-text-xs"],
      md: ["tw-text-sm"],
      lg: ["tw-text-base"],
      xl: ["tw-text-lg"],
    },
  },
  defaultVariants: {
    size: "md",
  },
});

type ITextInputProps = Omit<ComponentPropsWithRef<"input">, "size"> &
  VariantProps<typeof inputVariants> & {
    label?: ReactNode;
    textPrepend?: ReactNode;
    textAppend?: ReactNode;
    error?: string;
    confirmation?: string;
    tooltip?: string;
    autoselect?: ReactNode;
    action?: ReactNode;
    isFocused?: boolean;
  };

const TextInput = forwardRef<HTMLInputElement | null, ITextInputProps>(
  (
    {
      className,
      label,
      textPrepend,
      textAppend,
      id,
      error,
      confirmation,
      onFocus,
      onBlur,
      tooltip,
      autoselect,
      action,
      isFocused,
      value,
      name,
      placeholder,
      onChange,
      inputMode,
      autoComplete = "off",
      disabled = false,
      type = "text",
      size = "md",
      ...rest
    },
    ref,
  ) => {
    const [focused, setFocused] = useState(isFocused ? isFocused : false);

    const inputEl = useRef<HTMLInputElement>(null);
    const combinedRef = useCombinedRefs(ref, inputEl);

    const inputFocus = () => {
      inputEl.current?.focus();
    };

    useEffect(() => {
      if (isFocused) {
        inputFocus();
      }
    }, [isFocused]);

    return (
      <div
        className={twMerge(
          /* styles.textinput,*/ className,
          disabled ? "tw-opacity-60 tw-select-none" : "",
        )}
      >
        <div className="tw-relative">
          {label && (
            <Label
              className="tw-mb-2"
              htmlFor={id}
              onClick={inputFocus}
              disabled={disabled}
            >
              <span className="tw-mr-1 tw-align-middle">{label}</span>
              {tooltip && (
                <Helper
                  className="tw-mt-1 tw-inline-block tw-align-middle"
                  content={tooltip}
                />
              )}
            </Label>
          )}
          <div
            className={twMerge(
              "tw-relative tw-flex tw-flex-nowrap tw-bg-white tw-items-center tw-overflow-hidden tw-rounded tw-border tw-font-semibold tw-border-[#ebe8ef] tw-transition-colors tw-duration-300 tw-ease-out",
              /* styles.textinputWrapper,*/
              {
                /* [styles.focused]: focused && !error,*/
                /* [styles.error]: error,*/
                /* [styles.confirmation]: confirmation && !error,*/
                "hover:tw-border-primary/30":
                  !error && !disabled && !confirmation && !focused,
                "tw-border-primary": focused && !error,
                "tw-border-red-500": error,
                "tw-border-mint-500 tw-outline-none": confirmation && !error,
                "tw-cursor-not-allowed tw-shadow-none": disabled,
              },
            )}
          >
            {disabled && <div className="tw-absolute tw-inset-0 tw-z-[1]" />}

            {textPrepend && (
              <AdditionalText className="tw-select-none tw-pl-4 tw-leading-6">
                {textPrepend}
              </AdditionalText>
            )}

            <div className="tw-w-full">
              <input
                {...rest}
                placeholder={placeholder}
                autoComplete={autoComplete}
                type={type}
                value={value}
                name={name}
                id={id}
                ref={combinedRef}
                disabled={disabled}
                onChange={onChange}
                inputMode={inputMode}
                style={{ fontSize: "16px" }}
                className={twMerge(
                  "tw-flex tw-w-full tw-items-center tw-outline-none tw-shadow-none tw-text-ellipsis tw-border-0 tw-px-4 tw-py-0  tw-bg-white tw-text-black tw-leading-6 tw-appearance-none",
                  /* styles.textinputField,*/
                  /* styles[size],*/
                  inputVariants({ size }),
                  {
                    "tw-opacity-60 tw-select-none": disabled,
                    "tw-text-black/50": disabled,
                  },
                )}
                onFocus={(e) => {
                  setFocused(true);

                  if (onFocus) {
                    onFocus(e);
                  }
                }}
                onBlur={(e) => {
                  setFocused(false);

                  if (onBlur) {
                    onBlur(e);
                  }
                }}
              />
            </div>

            {textAppend && (
              <AdditionalText
                className={twMerge(
                  "tw-pr-4 tw-leading-6 tw-select-none",
                  textVariants({ size }),
                  {
                    "tw-invisible": !focused && !value,
                  },
                )}
              >
                {textAppend}
              </AdditionalText>
            )}

            {action}
          </div>
          {autoselect}
        </div>
        {error && <Error className="tw-ml-1 tw-mt-1">{error}</Error>}
        {confirmation && (
          <Text
            size="sm"
            className="tw-ml-1 tw-mt-1 tw-flex tw-items-center tw-font-semibold tw-text-mint-500"
          >
            <div className="tw-mr-1 tw-flex tw-h-[13px] tw-w-[13px] tw-items-center tw-justify-center tw-rounded-full tw-bg-mint-500">
              <Check
                strokeWidth={5}
                className="tw-h-auto tw-w-[9px] tw-text-white"
              />
            </div>
            {confirmation}
          </Text>
        )}
      </div>
    );
  },
);

export default TextInput;
