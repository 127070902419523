import { Text } from "@krea/customer-features/shared-components/text";
import twMerge from "@krea/customer-features/utils/tailwind-utils";

const BidItemLabel = ({
  className,
  text,
  accentColor,
}: {
  className?: string;
  text: string;
  accentColor?: "mint" | "primary";
}) => {
  return (
    <>
      <div
        className={twMerge(
          "tw-h-[22px] tw-rounded-t-sm tw-rounded-r-sm tw-mt-[5px] tw--ml-[9.5px] tw-text-xs tw-flex tw-items-center tw-px-3 tw-bg-gray-500 tw-relative",
          {
            "tw-bg-mint-500": accentColor === "mint",
            "tw-bg-primary": accentColor === "primary",
          },
          className,
        )}
      >
        <div
          className={twMerge(
            "tw-transform tw-rotate-90 tw-absolute tw-left-0 tw-bottom-0 tw--mb-[9.5px] tw-border-t-[10px] tw-border-gray-600 tw-border-l-[0px] tw-border-r-[10px] tw-border-r-transparent",
            {
              "tw-border-t-primary tw-brightness-50": accentColor === "primary",
              "tw-border-t-mint-500 tw-brightness-50": accentColor === "mint",
            },
          )}
        />
        <Text
          size="sm"
          className="tw-text-nowrap tw-font-bold tw-leading-none tw-text-white"
        >
          {text}
        </Text>
      </div>
    </>
  );
};

export default BidItemLabel;
