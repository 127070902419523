import { cva, VariantProps } from "class-variance-authority";
import { createElement, HTMLAttributes, forwardRef } from "react";

import twMerge from "@krea/customer-features/utils/tailwind-utils";

const textVariants = cva("", {
  variants: {
    size: {
      xs: "tw-text-xs",
      sm: "tw-text-sm",
      md: "tw-text-base",
      lg: "tw-text-lg",
      xl: "tw-text-xl",
      xxl: "tw-text-2xl",
    },
    htmlTag: {
      div: "",
      span: "",
      p: "tw-m-0",
      dl: "",
      dt: "",
      dd: "",
      label: "",
    },
  },
  defaultVariants: {
    size: "md",
  },
});

export interface ITextProps
  extends HTMLAttributes<HTMLElement>,
    VariantProps<typeof textVariants> {
  htmlFor?: string;
}

export const Text = forwardRef<HTMLElement, ITextProps>(
  ({ size = "md", children, className, htmlTag = "div", ...rest }, ref) => {
    return createElement(
      htmlTag ?? "div",
      {
        className: twMerge(
          "last:tw-mb-0 tw-text-black-500",
          textVariants({ size }),
          className,
        ),
        ref,
        ...rest,
      },
      children,
    );
  },
);
