export const lightenHexToRgb = (hex: string, percentage: number) => {
  // Convert hex to RGB
  const rgbValue = hexToRgb(hex);
  const [r, g, b] = rgbValue.split(" ").map((v) => parseInt(v, 10));

  // Convert RGB to HSL
  const [h, s, l] = rgbToHsl(r, g, b);

  // Increase the lightness
  const newL = Math.min(100, l + percentage);

  // Convert back to RGB
  const [newR, newG, newB] = hslToRgb(h, s, newL);

  // Convert back to hex
  return `${newR} ${newG} ${newB}`;
};

// Helper: Convert RGB to HSL
export const rgbToHsl = (r: number, g: number, b: number) => {
  r /= 255;
  g /= 255;
  b /= 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h, s, l;

  l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // Achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    if (h === undefined) {
      throw new Error("Invalid color");
    }
    h *= 60;
  }

  return [h, s * 100, l * 100];
};

// Helper: Convert HSL to RGB
export const hslToRgb = (h: number, s: number, l: number) => {
  s /= 100;
  l /= 100;

  const c = (1 - Math.abs(2 * l - 1)) * s;
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  const m = l - c / 2;

  let r, g, b;

  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (300 <= h && h < 360) {
    r = c;
    g = 0;
    b = x;
  }
  if (r === undefined || g === undefined || b === undefined) {
    throw new Error("Invalid color");
  }
  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);

  return [r, g, b];
};

export const hexToRgb = (hexColor: string) => {
  // Remove the hash at the start if it's there
  hexColor = hexColor.replace(/^#/, "");

  // Parse r, g, b values from the hexColor
  let r, g, b;
  if (hexColor.length === 3) {
    // For shorthand hexColor (#RGB)
    r = parseInt(hexColor[0] + hexColor[0], 16);
    g = parseInt(hexColor[1] + hexColor[1], 16);
    b = parseInt(hexColor[2] + hexColor[2], 16);
  } else if (hexColor.length === 6) {
    // For full hexColor (#RRGGBB)
    r = parseInt(hexColor.slice(0, 2), 16);
    g = parseInt(hexColor.slice(2, 4), 16);
    b = parseInt(hexColor.slice(4, 6), 16);
  } else {
    throw new Error("Invalid hex color format");
  }

  // Return the rgb "raw" format, example: xxx xxx xx
  // See: https://tailwindcss.com/docs/customizing-colors#using-css-variables
  return `${r} ${g} ${b}`;
};
