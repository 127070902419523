import i18n from "i18next";
import * as Yup from "yup";

import { CountryCode } from "@krea/shared/types/common";
import { isValidMobileNumber } from "@krea/shared/utils/isValidMobileNumber";

export const MobilePhoneNumberValidation = (countryCode: CountryCode) => {
  return Yup.string()
    .required(i18n.t("applicationForm.commons.phoneErrors.required"))
    .test(
      "phone-validity-libphonenumber",
      i18n.t("applicationForm.commons.phoneErrors.invalid"),
      (value) => {
        if (value !== undefined) {
          return isValidMobileNumber(value, countryCode);
        }
      },
    );
};
