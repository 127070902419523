import i18n from "i18next";
import * as Yup from "yup";

import { useCustomerFeaturesSettingsStore } from "../store/app-settings/useCustomerFeaturesSettingsStore";
import { orgNumValidator } from "../utils";

export const OrganisationValidation = () => {
  const countryCode = useCustomerFeaturesSettingsStore.getState().countryCode;

  return Yup.object().shape({
    organisationNumber: Yup.string()
      .required(
        i18n.t("applicationForm.commons.companyNameErrors.orgNumberRequired"),
      )
      .test("organisationNumber", function (value) {
        const { path, createError } = this;
        const isValid = orgNumValidator(value, countryCode).isValid;

        return (
          isValid ||
          createError({
            path,
            message: i18n.t(
              "applicationForm.commons.companyNameErrors.orgNumberInvalid",
            ),
          })
        );
      }),
    name: Yup.string().required(
      i18n.t("applicationForm.commons.companyNameErrors.orgNameRequired"),
    ),
  });
};
