import { create } from "zustand";

import { getPrimaryLanguage } from "@krea/customer-features/utils";
import {
  AppName,
  CountryCode,
  Currency,
  PreferredLanguageEnum,
} from "@krea/shared/types/common";
import {
  CURRENCY_BY_COUNTRY_CODE,
  getCountryCode,
} from "@krea/shared/utils/countryUtils";

import { useLoanApplicationFormStore } from "../loan-application-form/hooks";

export interface IAppSettings {
  countryCode: CountryCode;
  currency: Currency;
  preferredLanguage: PreferredLanguageEnum;
  appName: AppName;
}

interface IAppSettingsStore extends IAppSettings {
  setCountryCode: (countryCode: CountryCode) => void;
  setPreferredLanguage: (preferredLanguage: PreferredLanguageEnum) => void;
}

export const useCustomerFeaturesSettingsStore = create<IAppSettingsStore>()((
  set,
  getState,
) => {
  const countryCode = getCountryCode();

  return {
    countryCode,
    currency: CURRENCY_BY_COUNTRY_CODE[countryCode],
    preferredLanguage: getPrimaryLanguage(),
    appName: import.meta.env.REACT_APP_NAME,
    setCountryCode: (countryCode: CountryCode) => {
      const currency = CURRENCY_BY_COUNTRY_CODE[countryCode];
      const appName = getState().appName;

      if (appName === AppName.CUSTOMER || appName === AppName.PARTNER) {
        console.error(
          `Changing country code is not allowed for ${appName} app`,
        );

        return;
      }
      set({ countryCode, currency });
    },

    setPreferredLanguage: (preferredLanguage: PreferredLanguageEnum) =>
      set({ preferredLanguage }),
  };
});
