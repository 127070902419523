import { type ClassValue, clsx } from "clsx";
import { extendTailwindMerge } from "tailwind-merge";

export function twMerge(...inputs: ClassValue[]) {
  const customTwMerge = extendTailwindMerge({ prefix: "tw-" });

  return customTwMerge(clsx(inputs));
}

export default twMerge;
