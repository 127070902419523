import React from "react";
import SVG from "react-inlinesvg";

import InfoIcon from "@krea/customer-features/images/svg/info.svg";
import QuestionIcon from "@krea/customer-features/images/svg/question.svg";
import AppTooltip from "@krea/customer-features/shared-components/tooltip";
import { twMerge } from "@krea/shared/design-system/tailwind-utils";

export const Helper = ({
  className,
  content,
  useQuestionIcon = false,
}: {
  className?: string;
  content: React.ReactNode;
  useQuestionIcon?: boolean;
}) => {
  return (
    <div
      aria-hidden
      className={twMerge(
        "tw-inline-flex tw-justify-center tw-items-center tw-cursor-help tw-text-gray-900/65 tw-fill-gray-900/65 tw-align-middle tw-select-none ",
        className,
      )}
    >
      <AppTooltip
        className={twMerge(
          "tw-w-4 tw-relative",
          "before:tw-block before:tw-content-[''] before:tw-p-0 before:tw-pb-[100%]",
          "after:tw-block after:tw-content-[''] after:tw-absolute after:tw-top-1/2 after:tw-left-1/2 after:tw-w-8 after:tw-h-8",
        )}
        content={content}
      >
        <SVG
          className="tw-absolute tw-left-0 tw-top-0 tw-h-auto tw-w-full"
          src={useQuestionIcon ? QuestionIcon : InfoIcon}
        />
      </AppTooltip>
    </div>
  );
};
