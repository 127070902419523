import { type ClassValue, clsx } from "clsx";
import { KeyboardEvent } from "react";
import { extendTailwindMerge } from "tailwind-merge";

import { CountryCode } from "../types/common";

export const getCompanyBrandName = (countryCode: CountryCode): string => {
  switch (countryCode) {
    case CountryCode.FI:
      return "Kredita";
    case CountryCode.SE:
      return "Krea";
    default:
      return "";
  }
};

export const cn = (...inputs: ClassValue[]) => {
  const customTwMerge = extendTailwindMerge({ prefix: "tw-" });

  return customTwMerge(clsx(inputs));
};

export const isHotkey = (hotkey: string, event: KeyboardEvent) => {
  const hotkeyPressed = hotkey.split("+");

  const isHotkeyPressed = hotkeyPressed.every((key) => {
    if (key === "mod") {
      const metaKey = event.metaKey;

      return metaKey;
    } else if (key === "shift") {
      const shiftKey = event.shiftKey;

      return shiftKey;
    } else {
      return event.key === key;
    }
  });

  return isHotkeyPressed;
};
