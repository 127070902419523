import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

import { TEST_IDS, isMobile } from "@krea/customer-features/utils";
import { Slider as BaseSlider } from "@krea/shared/design-system/Slider";
import { twMerge } from "@krea/shared/design-system/tailwind-utils";

import Button from "../button";
import { Error } from "../fields/error";
import TextInput from "../fields/input";
import { Label } from "../fields/label";

interface ISlider {
  id: string;
  label: string;
  value?: number;
  htmlFor: string;
  values: number[];
  error: string;
  onSliderChange: (values: number[]) => void;
  onValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  postfix?: string | null;
  formatter: (value: number) => string;
  inputFormatter?: (value: number) => string;
  isCustomInput: boolean;
  labelClassName?: string;
  period?: boolean;
  name?: string;
  onCustomToggle: () => void;
  inputOnBlur?: () => void;
}

export const Slider = (props: ISlider) => {
  const {
    id,
    label,
    value,
    htmlFor,
    values,
    error,
    onSliderChange,
    onValueChange,
    placeholder,
    postfix,
    formatter,
    inputFormatter,
    isCustomInput,
    labelClassName,
    period,
    onCustomToggle,
    inputOnBlur,
  } = props;
  const { t } = useTranslation();
  const thumbRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const valueIndex = values.indexOf(value ?? 0);
  const valuesLength = values.length;

  const inputValue = (value: number) => {
    if (value !== 0) {
      if (inputFormatter && value) {
        return inputFormatter(value);
      } else {
        return value;
      }
    } else {
      return "";
    }
  };

  const onLabelClick = () => {
    thumbRef.current?.focus();
  };

  const onInputLabelClick = () => {
    inputRef.current?.focus();
  };

  const onCustomToggleClick = () => {
    onCustomToggle();

    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <div
      id={id}
      tabIndex={0}
      aria-label={`${label} ${value?.toString().replace(/\s/g, "")}`}
    >
      <div className="tw-flex tw-w-full tw-flex-col">
        <div
          className={twMerge(
            {
              "tw-items-center": isCustomInput,
              "tw-flex-col tw-items-start sm:tw-flex-row sm:tw-items-center":
                !isCustomInput,
            },
            "tw-flex tw-flex-wrap tw-justify-between",
          )}
        >
          {!isCustomInput ? (
            <label
              className={twMerge(
                "tw-select-none tw-font-semibold tw-text-black-300",
                "tw-m-0 tw-flex tw-flex-wrap tw-p-0",
                labelClassName,
              )}
              onClick={onLabelClick}
              htmlFor={htmlFor}
              aria-labelledby={htmlFor}
              style={isMobile() ? { fontSize: "15px" } : { fontSize: "16px" }}
            >
              <span className="tw-mr-2 tw-block">{label}</span>

              <span className={twMerge("tw-block tw-text-black")}>
                {value && formatter(value)}
              </span>
            </label>
          ) : (
            <Label
              className="tw-mr-2 tw-block"
              htmlFor={htmlFor}
              aria-label={htmlFor}
            >
              <span
                className={twMerge("tw-align-middle")}
                onClick={onInputLabelClick}
              >
                {label}
              </span>
            </Label>
          )}

          <Button
            variant="text"
            size="sm"
            className="tw-mb-0 tw-shrink-0"
            onClick={onCustomToggleClick}
            data-test-id={TEST_IDS.common.forms.loanAmountSliderOrInputSwitch}
          >
            {isCustomInput
              ? t("slider.backToSlider")
              : period
                ? t("applicationForm.commons.exactRequestedPeriodButton")
                : t("applicationForm.commons.exactRequestedAmountButton")}
          </Button>
        </div>

        {isCustomInput ? (
          <TextInput
            ref={inputRef}
            size="lg"
            type="text"
            inputMode="numeric"
            textAppend={postfix}
            id={htmlFor}
            className="tw-my-2"
            autoComplete="off"
            onChange={onValueChange}
            value={inputValue(value ?? 0)}
            placeholder={placeholder}
            isFocused={isCustomInput}
            error={error}
            onBlur={inputOnBlur}
            data-test-id={TEST_IDS.common.forms.loanAmountInput}
          />
        ) : (
          <div
            className="tw-mb-1"
            data-test-id={TEST_IDS.common.forms.loanAmountSlider}
          >
            <BaseSlider
              ref={thumbRef}
              className="tw-h-[54px]"
              onValueChange={onSliderChange}
              value={[valueIndex < 0 ? 0 : valueIndex]}
              step={1}
              min={0}
              max={valuesLength > 0 ? valuesLength - 1 : 0}
            />
            {error && <Error className="tw-mt-1">{error}</Error>}
          </div>
        )}
      </div>
    </div>
  );
};
