import React from "react";

import { twMerge } from "@krea/shared/design-system/tailwind-utils";

import { Text } from "../../text";

export const Error = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <Text
      size="sm"
      className={twMerge(
        "tw-font-normal tw-leading-5 tw-text-red-500",
        className,
      )}
    >
      {children}
    </Text>
  );
};
