import axios from "axios";

import {
  settings as commonSettings,
  initCommonSettings,
} from "@krea/customer-features/settings";
import { CountryCode } from "@krea/shared/types/common";
import { AppSettingsProps } from "@krea/customer-features/types/settings";
import { useCustomerFeaturesSettingsStore } from "@krea/customer-features/store/app-settings/useCustomerFeaturesSettingsStore";
import { useLoanApplicationFormStore } from "@krea/customer-features/store/loan-application-form/hooks";

const getKreaBaseUrl = () => {
  let url = "http://localhost:8000";

  if (import.meta.env.MODE === "production") {
    url = "https://customer-api.krea.se";
  } else if (import.meta.env.MODE === "staging") {
    url = "https://staging.krea.se";
  } else if (import.meta.env.MODE === "test") {
    url = "https://test.krea.se";
  } else if (import.meta.env.MODE === "local-docker") {
    url = "http://localhost:8000";
  }

  return url;
};

// only init/merge common settings once
let mergedSettings: AppSettingsProps | undefined = undefined;
if (!mergedSettings) {
  mergedSettings = initCommonSettings({
    environment: import.meta.env.MODE || "local",
    backendBaseUrl: getKreaBaseUrl(),
  });
}
const settings = mergedSettings;

const getCustomerWebUrl = (): string => {
  const countryCode = useCustomerFeaturesSettingsStore.getState().countryCode;

  let url = "http://localhost:3003";

  if (settings.environment === "production") {
    switch (countryCode) {
      case CountryCode.SE:
        url = "https://app.krea.se";
        break;
      case CountryCode.FI:
        url = "https://app.kredita.fi";
        break;
    }
  } else if (settings.environment === "staging") {
    switch (countryCode) {
      case CountryCode.SE:
        url = "https://staging-app.krea.se";
        break;
      case CountryCode.FI:
        url = "https://staging-app.kredita.fi";
        break;
    }
  }

  return url;
};

export const setCountryCode = (countryCode: CountryCode) => {
  const changeCountryCode =
    useCustomerFeaturesSettingsStore.getState().setCountryCode;

  changeCountryCode(countryCode);

  const customerWebUrl = getCustomerWebUrl();

  settings.customerWebUrl = customerWebUrl;
  commonSettings.customerWebUrl = customerWebUrl;
};

// Set global axios config
axios.defaults.baseURL = settings.backendBaseUrl;
axios.defaults.withCredentials = false;
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

export default settings;
