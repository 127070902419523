import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { Check } from "lucide-react";
import React from "react";

import { Error } from "@krea/shared/design-system/fields/Error";
import { Label } from "@krea/shared/design-system/fields/Label";
import { twMerge } from "@krea/shared/design-system/tailwind-utils";

type CheckboxProps = Omit<
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>,
  "onCheckedChange" | "onChange"
> & {
  label?: React.ReactNode;
  labelClassName?: string;
  error?: string;
  labelUnbolded?: string;
  onChange?: (checked: boolean) => void;
};

const CheckBox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxProps
>(
  (
    {
      className,
      disabled,
      tabIndex,
      error,
      checked,
      onKeyDown,
      onChange,
      id,
      labelUnbolded,
      label,
      labelClassName,
      ...props
    },
    ref,
  ) => {
    return (
      <div className={twMerge("", className)}>
        <Label
          className={twMerge(
            // styles.label,
            "tw-flex tw-items-top",
            "tw-text-gray-900",
            { "tw-text-gray-500": disabled },
            labelClassName,
          )}
          htmlFor={id}
          // tabIndex and onKeyDown are passed to the "wrapping" label to make the checkbox accessible via keyboard
          tabIndex={tabIndex}
          onKeyDown={onKeyDown}
        >
          <CheckboxPrimitive.Root
            data-test-id="consent-checkbox"
            ref={ref}
            className={twMerge(
              "tw-peer tw-mr-2 tw-h-[19px] tw-w-[19px] tw-shrink-0 tw-rounded-sm tw-border-2 tw-border-gray-500 data-[state=checked]:tw-border-primary tw-shadow focus-visible:tw-outline-none focus-visible:tw-ring-1 focus-visible:tw-ring-ring disabled:tw-cursor-not-allowed disabled:tw-opacity-50 data-[state=checked]:tw-bg-primary data-[state=checked]:tw-text-white",
              { "tw-border-red-500": error },
              { "tw-bg-white": !checked },
            )}
            checked={checked}
            id={id}
            disabled={disabled}
            onCheckedChange={(checked) => onChange?.(checked === true)}
            {...props}
          >
            <CheckboxPrimitive.Indicator
              className={twMerge(
                "tw-flex tw-items-center tw-justify-center tw-text-current",
              )}
            >
              <Check
                strokeWidth={4}
                className="tw-ml-px tw-h-[16px] tw-w-[16px]"
              />
            </CheckboxPrimitive.Indicator>
          </CheckboxPrimitive.Root>
          {label && <span>{label}</span>}

          {labelUnbolded && (
            <span className="tw-font-normal">{labelUnbolded}</span>
          )}
        </Label>
        {error && <Error className="tw-ml-[26px] tw-mt-1">{error}</Error>}
      </div>
    );
  },
);
CheckBox.displayName = CheckboxPrimitive.Root.displayName;

export default CheckBox;
