import {
  CountryCode,
  Metadata as MetadataReader,
  parsePhoneNumberFromString,
} from "libphonenumber-js/max";

export const isValidMobileNumber = (
  number: string | null | undefined,
  countryCode: CountryCode,
) => {
  if (!number) {
    return false;
  }
  const metadata = new MetadataReader();
  metadata.selectNumberingPlan(countryCode);
  const phoneNumber = parsePhoneNumberFromString(number, countryCode);

  const validTypes = ["FIXED_LINE_OR_MOBILE", "MOBILE", undefined];

  if (phoneNumber) {
    const type = phoneNumber.getType();

    return phoneNumber.isValid() && validTypes.includes(type);
  }

  return false;
};
