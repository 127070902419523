export enum PreferredLanguageEnum {
  sv = "sv",
  fi = "fi",
  en = "en",
}

export enum PromotionTypeEnum {
  INTEREST_FREE = "INTEREST_FREE",
  AMORTISATION_FREE = "AMORTISATION_FREE",
}

export enum CountryCode {
  SE = "SE",
  FI = "FI",
}

export enum Currency {
  SEK = "SEK",
  EUR = "EUR",
}

export enum AppName {
  ADMIN = "ADMIN",
  PARTNER = "PARTNER",
  CUSTOMER = "CUSTOMER",
  WIDGET = "WIDGET",
}
