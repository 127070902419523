import { AnimatePresence, motion } from "motion/react";
import { ChevronDown } from "lucide-react";
import { useRef, useState, useEffect, RefObject, ReactNode } from "react";

import { twMerge } from "@krea/customer-features/utils/tailwind-utils";

const motionItem = {
  hidden: { zIndex: 0 },
  visible: {
    zIndex: 1,
    transition: {
      duration: 0.6,
      ease: "easeOut",
    },
  },
};

const motionIcon = {
  hidden: {
    transform: "rotateZ(0deg)",
    transition: {
      duration: 0.6,
      ease: "easeOut",
    },
  },
  visible: {
    transform: "rotateZ(180deg)",
    transition: {
      duration: 0.6,
      ease: "easeOut",
    },
  },
};

const AccordionItemIcon = ({ isOpen }: { isOpen: boolean }) => {
  return (
    <motion.div
      className={twMerge("tw-relative tw-mt-0 tw-size-6 tw-shrink-0")}
      variants={motionIcon}
      initial="hidden"
      animate={isOpen ? "visible" : "hidden"}
    >
      <ChevronDown
        strokeWidth={3}
        className="tw-absolute tw-h-auto tw-w-full tw-text-primary"
      />
    </motion.div>
  );
};

export const AccordionItem = ({
  title,
  children,
  id,
  className,
  titleStyling,
}: {
  title: string;
  children: ReactNode;
  id?: string;
  className?: string;
  titleStyling?: string;
}) => {
  const [expanded, setExpanded] = useState(false);
  const refContainer = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (expanded) refContainer.current?.focus();
  }, [expanded]);

  return (
    <motion.section
      className={twMerge(
        "tw-relative tw-cursor-pointer tw-overflow-hidden tw-rounded tw-border",
        className,
      )}
      variants={motionItem}
      initial="hidden"
      animate={expanded ? "visible" : "hidden"}
      id={`accordion${id}id`}
      aria-expanded={expanded ? "true" : "false"}
      aria-disabled={expanded ? false : undefined}
      tabIndex={0}
    >
      <div
        aria-hidden="true"
        className="tw-m-0 tw-flex tw-items-center tw-justify-between tw-p-4 md:tw-px-6  md:tw-py-4"
        role="button"
        aria-controls={`sect-${id}`}
        onClick={() => setExpanded(!expanded)}
      >
        <p
          className={twMerge(
            "tw-pb-0 tw-m-0 tw-pr-4 tw-text-xl tw-font-semibold",
            titleStyling,
          )}
        >
          {title}
        </p>

        <AccordionItemIcon isOpen={expanded} />
      </div>

      <AnimatePresence>
        <motion.div
          ref={refContainer as RefObject<HTMLDivElement>}
          id={`sect-${id}`}
          role="region"
          aria-labelledby={`accordion${id}id`}
          tabIndex={-1}
          className={twMerge("tw-overflow-hidden")}
          initial={false}
          animate={expanded ? "open" : "collapsed"}
          variants={{
            open: { opacity: 1, height: "auto" },
            collapsed: { opacity: 0, height: 0 },
          }}
          transition={{ duration: 0.6, ease: "easeOut" }}
        >
          <motion.div
            className="tw-px-4 tw-py-3 md:tw-px-6 md:tw-pb-7 md:tw-pt-3"
            animate={expanded ? "open" : "collapsed"}
            variants={{
              open: { visibility: "visible" },
              collapsed: {
                visibility: "hidden",
                transition: {
                  delay: 0.6,
                },
              },
            }}
          >
            {children}
          </motion.div>
        </motion.div>
      </AnimatePresence>
    </motion.section>
  );
};
