import { Text } from "@krea/customer-features/shared-components/text";
import { isMobile } from "@krea/customer-features/utils";
import { twMerge } from "@krea/shared/design-system/tailwind-utils";

const LabeledRadioButton = ({ title, name, value, checked, onChange }) => {
  return (
    <label
      role="button"
      tabIndex="0"
      aria-label={title}
      aria-pressed={checked}
      className={twMerge(
        "tw-flex tw-items-center tw-bg-white tw-border tw-border-primary/20 tw-rounded tw-relative tw-w-full tw-py-3 tw-px-3",
        {
          "tw-bg-primary-150": checked,
        },
      )}
    >
      <input
        type="radio"
        name={name}
        value={value}
        className="tw-invisible tw-absolute"
        checked={checked}
        onChange={onChange}
      />
      <div
        className={twMerge(
          "tw-flex tw-shrink-0 tw-mr-3 tw-border tw-rounded-full tw-justify-center tw-items-center",
          {
            "tw-border-primary": checked,
            "tw-border-gray-400": !checked,
          },
        )}
        style={{ width: "20px", height: "20px" }}
        ref={(el) => {
          if (el) {
            el.style.setProperty("border-width", "2px", "important");
          }
        }}
      >
        {checked && (
          <div
            className="tw-rounded-full tw-bg-primary"
            style={{ width: "8px", height: "8px" }}
          />
        )}
      </div>
      <Text
        size={isMobile ? "sm" : "md"}
        className={twMerge("tw-font-semibold", { "tw-text-primary": !checked })}
      >
        {title}
      </Text>
    </label>
  );
};

export default LabeledRadioButton;
