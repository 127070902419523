import i18n from "i18next";
import * as Yup from "yup";

import { minFinancingAmountPerCountry } from "@krea/customer-features/utils";

import { useCustomerFeaturesSettingsStore } from "../store/app-settings/useCustomerFeaturesSettingsStore";

export const RequestedLoanAmountValidation = () => {
  const countryCode = useCustomerFeaturesSettingsStore.getState().countryCode;

  return Yup.number()
    .moreThan(
      minFinancingAmountPerCountry[countryCode].numericValue - 1,
      i18n.t("applicationForm.common.minimumFinancingAmountValidation", {
        amount: minFinancingAmountPerCountry[countryCode].stringValue,
      }),
    )
    .typeError(
      i18n.t("applicationForm.commons.requestedAmountErrors.typeError"),
    )
    .required(i18n.t("applicationForm.commons.requestedAmountErrors.required"));
};

export const OptionalAmountValidation = () => {
  const countryCode = useCustomerFeaturesSettingsStore.getState().countryCode;

  return Yup.number()
    .moreThan(
      minFinancingAmountPerCountry[countryCode].numericValue - 1,
      i18n.t("applicationForm.common.minimumFinancingAmountValidation", {
        amount: minFinancingAmountPerCountry[countryCode].stringValue,
      }),
    )
    .typeError(
      i18n.t("applicationForm.commons.requestedAmountErrors.typeError"),
    )
    .nullable();
};
