import {
  GraphQLClient,
  RequestMiddleware,
  ResponseMiddleware,
} from "graphql-request";

import { getAccessToken } from "@krea/customer-features/services/httpService";
import { settings } from "@krea/customer-features/settings";
import { AppName } from "@krea/shared/types/common";

import { getCookie } from "../services/cookieService";
import { useCustomerFeaturesSettingsStore } from "../store/app-settings/useCustomerFeaturesSettingsStore";

const requestMiddleware: RequestMiddleware = async (request) => {
  const appName = useCustomerFeaturesSettingsStore.getState().appName;

  const currentAccessToken = await getAccessToken();

  return {
    ...request,
    headers: {
      ...request.headers,
      authorization: `Bearer ${currentAccessToken}`,
      ...(appName === AppName.ADMIN
        ? { "X-CSRFToken": getCookie("csrftoken") ?? "" }
        : {}),
    },
  };
};

const responseMiddleware: ResponseMiddleware = (response) => {
  // About the "response" object:
  // If response is an error it is an instance of Error that has a response and request property
  // * Examples:
  //    * response.request (request object)
  //    * response.response.errors (errors from response object)
  // If it's not an error it is the actual response object (with data, errors, etc. properties)

  if (response instanceof Error) {
    // console.log('response', response?.response)
  }
};

export const client = new GraphQLClient(
  `${settings.backendBaseUrl}/api/graphql`,
  {
    errorPolicy: "none",
    requestMiddleware,
    responseMiddleware,
  },
);
