import {
  CountryCode,
  Currency,
  PreferredLanguageEnum,
} from "@krea/shared/types/common";

const CURRENCY_BY_COUNTRY_CODE = {
  [CountryCode.SE]: Currency.SEK,
  [CountryCode.FI]: Currency.EUR,
} as const;

const getCountryCode = (): CountryCode => {
  const topDomainToCountryMap: Record<string, CountryCode> = {
    ".se": CountryCode.SE,
    ".fi": CountryCode.FI,
  };

  try {
    const urlRegex = window.location.host.match(/(.se)|(.fi)|(localhost)/);
    const topDomainFromUrl = urlRegex?.[0];
    const countryCodeFromLocalStorage = localStorage.getItem(
      "countryCode",
    ) as CountryCode | null;

    // Set from top domain
    if (
      topDomainFromUrl &&
      Object.keys(topDomainToCountryMap).includes(topDomainFromUrl)
    ) {
      const countryCode = topDomainToCountryMap[topDomainFromUrl];
      localStorage.setItem("countryCode", countryCode);

      return countryCode;
    } else if (
      countryCodeFromLocalStorage &&
      Object.values(topDomainToCountryMap).includes(countryCodeFromLocalStorage)
    ) {
      // If we are not on any domain in 'topDomainToCountryMap', then we are in some dev env
      // and we want to be able to control/set the country code as developer using local storage

      return countryCodeFromLocalStorage;
    } else {
      localStorage.setItem("countryCode", CountryCode.SE);

      return CountryCode.SE;
    }
  } catch (e) {
    console.error(
      "Error getting country code from top domain(or localStorage), defaulting to SE. Error: ",
      e,
    );

    return CountryCode.SE;
  }
};

const getPrimaryLanguage = () => {
  if (getCountryCode() === CountryCode.FI) {
    return PreferredLanguageEnum.fi;
  }

  return PreferredLanguageEnum.sv;
};

export {
  CountryCode,
  CURRENCY_BY_COUNTRY_CODE,
  getCountryCode,
  getPrimaryLanguage,
};
