import { useQuery } from "@tanstack/react-query";

import HttpService from "@krea/customer-features/services/httpService";
import { settings } from "@krea/customer-features/settings";
import { useCustomerFeaturesSettingsStore } from "@krea/customer-features/store/app-settings/useCustomerFeaturesSettingsStore";
import { CountryCode } from "@krea/shared/types/common";

const instanceWithoutInterceptors =
  HttpService.createInstanceWithoutInterceptors();

export interface ICompanySearchItem {
  company_name: string;
  organisation_number: string;
}

const companySearch = async (
  countryCode: CountryCode,
  query?: string,
  signal?: AbortSignal,
) => {
  let searchQuery = "";

  if (query) {
    searchQuery = query.replace(/-/g, "");
  }

  return instanceWithoutInterceptors.get<ICompanySearchItem[]>(
    `${settings.backendBaseUrl}/company-data/api/v1/company-search?country_code=${countryCode}&query=${encodeURIComponent(searchQuery)}`,
    { signal },
  );
};

const shouldEnableSearch = (
  countryCode: CountryCode,
  query?: string,
): boolean => {
  let shouldSearch = false;

  if (query) {
    /**
     * This contains logic to meet the requirements for the search endpoints for the different countries.
     * When it comes to min length of the query, and depending on if its orgNum or orgName search,
     * it's not the same for all countries.
     * To try/test search capabilities, to that here https://connect-portal.creditsafe.com/.
     */
    const trimmedQuery = query.replace(/-/g, "");
    const isOnlyNumbers = /^\d+$/.test(trimmedQuery);

    switch (countryCode) {
      case CountryCode.SE:
        if (isOnlyNumbers) {
          if (trimmedQuery.length >= 6) {
            shouldSearch = true;
          }
        } else {
          if (query.length >= 3) {
            shouldSearch = true;
          }
        }
        break;
      case CountryCode.FI:
        if (isOnlyNumbers) {
          if (trimmedQuery.length >= 1) {
            shouldSearch = true;
          }
        } else {
          if (query.length >= 2) {
            shouldSearch = true;
          }
        }
        break;
      default:
        break;
    }
  }

  return shouldSearch;
};

export const useCompanySearch = (query?: string) => {
  const countryCode = useCustomerFeaturesSettingsStore(
    (state) => state.countryCode,
  );

  return useQuery({
    queryKey: ["COMPANY_SEARCH", query, countryCode],
    queryFn: async ({ signal }) => {
      const response = await companySearch(
        countryCode,
        query?.replace(/-/g, ""),
        signal,
      );
      const results = response.data;
      const hasDuplicates =
        results
          .slice()
          .map((company) => company.company_name.toLowerCase())
          .filter((v, i, a) => a.indexOf(v) !== i).length > 0;

      return { results, hasDuplicates };
    },
    enabled: shouldEnableSearch(countryCode, query),
    keepPreviousData: true,
    staleTime: Infinity,
  });
};
