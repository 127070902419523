import { cloneDeep } from "lodash";

import { CountryCode } from "@krea/shared/types/common";

import { useCustomerFeaturesSettingsStore } from "./store/app-settings/useCustomerFeaturesSettingsStore";
import { AppSettingsProps, SettingsContactsProps } from "./types/settings";

const environment =
  import.meta.env.REACT_APP_APPLICATION_ENV || import.meta.env.MODE || "local";

const getGtmID = () => {
  const countryCode = useCustomerFeaturesSettingsStore.getState().countryCode;

  switch (countryCode) {
    case CountryCode.SE:
      return "GTM-PDQ5MPJ";
    case CountryCode.FI:
      return "GTM-56JQDSP";
    default:
      return "";
  }
};

const getCountryWebsiteUrl = () => {
  const countryCode = useCustomerFeaturesSettingsStore.getState().countryCode;
  let url = null;

  switch (environment) {
    case "production":
      switch (countryCode) {
        case CountryCode.SE:
          url = "https://krea.se";
          break;
        case CountryCode.FI:
          url = "https://kredita.fi";
          break;
      }
      break;
    case "staging":
      switch (countryCode) {
        case CountryCode.SE:
          url = "https://staging-next.krea.se";
          break;
        case CountryCode.FI:
          url = "https://staging-next.kredita.fi";
          break;
      }
      break;
    case "test":
      switch (countryCode) {
        case CountryCode.SE:
          url = "https://test-next.krea.se";
          break;
        case CountryCode.FI:
          url = "https://test-next.kredita.fi";
          break;
      }

      break;

    default:
      switch (countryCode) {
        case CountryCode.SE:
          url = "http://localhost:3002";
          break;
        case CountryCode.FI:
          url = "http://localhost:3005";
          break;
      }
      break;
  }

  if (!url) {
    throw Error("countryWebsiteUrl cant be null, not configured correct.");
  }

  return url;
};

const getBackendBaseUrl = () => {
  switch (environment) {
    case "production":
      return "https://customer-api.krea.se";
    case "staging":
      return "https://staging.krea.se";
    case "test":
      return "https://test.krea.se";

    case "local-docker":
      if (typeof window === "undefined") {
        return "http://host.docker.internal:8000";
      } else {
        return "http://localhost:8000";
      }
    default:
      return "http://localhost:8000";
  }
};

const getContacts = (): SettingsContactsProps => {
  const countryCode = useCustomerFeaturesSettingsStore.getState().countryCode;

  switch (countryCode) {
    case CountryCode.SE:
      return {
        phone: {
          value: "08-551 082 62",
          link: "+46855108262",
        },
        email: "kundservice@krea.se",
        adress: {
          value: "Valhallavägen 60, 114 27 Stockholm",
          link: "https://maps.google.com/?q=KREA%20F%C3%B6retagsl%C3%A5n%20Valhallav%C3%A4gen%2060,%20114%2027%20Stockholm",
        },
        openingHours: [
          {
            day: `footer.mondayToThursdayLabel.${countryCode}`,
            time: "09 - 18",
          },
          { day: "footer.fridayLabel", time: "09 - 17" },
        ],
      };

    case CountryCode.FI:
      return {
        phone: {
          value: "09 315 316 10",
          link: "+358931531610",
        },
        email: "asiakaspalvelu@kredita.fi",
        adress: {
          value: "Linnoitustie 6, 02600 Espoo",
          link: "https://www.google.com/maps/place/Wonderland+Work+Lepp%C3%A4vaara/@60.2117072,24.8134392,17z/data=!4m15!1m8!3m7!1s0x468df5d7e2cae661:0x98e466774ad35947!2sLinnoitustie+6,+02600+Espoo,+Finland!3b1!8m2!3d60.2117072!4d24.8134392!16s%2Fg%2F11c88dc9hv!3m5!1s0x468df5bf2d23861b:0x8d3c916b48882443!8m2!3d60.2119617!4d24.8147542!16s%2Fg%2F11ng59wsqy",
        },
        openingHours: [
          {
            day: `footer.mondayToThursdayLabel.${countryCode}`,
            time: "10 - 16",
          },
        ],
      };

    default:
      throw new Error("There is no contact information for this country");
  }
};

const getAppTitle = () => {
  const countryCode = useCustomerFeaturesSettingsStore.getState().countryCode;

  switch (countryCode) {
    case CountryCode.SE:
      return "Krea";
    case CountryCode.FI:
      return "Kredita";
    default:
      return "Krea";
  }
};

export let settings: AppSettingsProps = {
  contacts: getContacts(),
  countryWebsiteUrl: getCountryWebsiteUrl(),
  gtmId: getGtmID(), // Google tagmanager Id
  appTitle: getAppTitle(),
  defaultListLimit: 25,
  backendBaseUrl: getBackendBaseUrl(),
  environment,
  customerWebUrl: undefined,
  unauthorizedPaths: [],
};

export const initCommonSettings = (
  appSpecificSettings: Partial<AppSettingsProps>,
): AppSettingsProps => {
  settings = {
    ...settings,
    ...cloneDeep(appSpecificSettings),
  };

  return settings;
};
